import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { CmsService, PageContext, RoutingService, StateWithCms } from "@spartacus/core";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class SsabCmsService extends CmsService {
  constructor(store: Store<StateWithCms>, routingService: RoutingService) {
    super(store, routingService);
  }

  hasPage(pageContext: PageContext, forceReload?: boolean): Observable<boolean> {
    return super.hasPage(pageContext, forceReload);
  }
}
