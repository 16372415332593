<scanner-header-main title="{{'ssab.scanner.qr.title'|cxTranslate}}" [hideBack]="true"></scanner-header-main>
<div class="bg-white pb-4">
  <div class="alert alert-info">
    <div class="container text-left">
      <div class="info-header cursor-pointer" (click)="toggleInfo($event)">
        <i class="ssab-icon icon-chevron-down icon-inline mr-2"></i>
        <span [innerHTML]="'ssab.scanner.home.tubularInfoTitle'|cxTranslate"></span>
      </div>
      <div class="info-content mt-2 d-none">
        <span [innerHTML]="'ssab.scanner.home.tubularInfoDetails'|cxTranslate"></span>&nbsp;
        <span [innerHTML]="'ssab.scanner.home.tubularInfoAccess'|cxTranslate"></span>
      </div>
    </div>
  </div>
  <div class="text-center pt-3 pb-3">
    <button class="btn text-uppercase" (click)="openScannerHowToScanModal()">
      <i class="ssab-icon icon-system-info icon-inline mr-2"></i>
      <span>{{'ssab.scanner.home.title1'|cxTranslate}}</span>
    </button>

  </div>

  <app-qrscanner (valueChange)="handleInputValueChange($event)"></app-qrscanner>

  <hr class="ml-3 mr-3"/>

  <app-search-input [inputValue]="ocrResult" (valueChange)="handleInputValueChange($event)"></app-search-input>
</div>
