var productVariants = {
  style: "Style",
  selectedStyle: "Selected style",
  size: "Size",
  color: "Color",
  sizeGuideLabel: "Style guide"
};
var productVariants$1 = {
  productVariants: productVariants
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  productVariants: productVariants$1
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const productVariantsTranslations = {
  en
};
// expose all translation chunk mapping for variants sub features
const productVariantsTranslationChunksConfig = {
  productVariants: ['productVariants']
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * Generated bundle index. Do not edit.
 */

export { productVariantsTranslationChunksConfig, productVariantsTranslations };
