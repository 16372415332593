<ng-container *ngIf="enabled$ | async">
  <ng-container *ngIf="selectedUnit$ | async as selectedUnit">
    <h4 class="text-lg d-sm-block d-lg-none text-primary">{{ 'ssab.navigation.user.select' | cxTranslate }}</h4>
    <ng-select #units
               [clearable]="false"
               [closeOnSelect]="true"
               (change)="selectUnit($event)"
               [searchFn]="customSearchFn"
               dropdownPosition="bottom"
               bindLabel="name"
               bindValue="uid"
               [searchable]="true"
               [typeahead]="unitInput$"
               [items]="units$ | async"
               placeholder="{{ selectedUnit.name }} {{ selectedUnit.uid }}"
    >
      <ng-template ng-header-tmp>
        <i class="ssab-icon icon-navigation-search icon-inline icon-gray-light"></i>
        <input class="pl-2" placeholder="{{ 'searchBox.customerSelect' | cxTranslate }}" style="line-height: 24px" type="text" (input)="units.filter($event.target.value)"/>
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        {{ item.name }} <span *ngIf="item.city">({{ item.city }})</span> {{ item.uid }}
      </ng-template>
    </ng-select>
  </ng-container>
</ng-container>
