import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {OccEndpointsService} from '@spartacus/core';
import {
  SsabClaim,
  SsabClaimAssigneeRequest,
  SsabClaimCreateRequest,
  SsabClaimList,
  SsabClaimRequest,
  SsabClaimStatusRequest
} from "../../model/claim.mode";
import {catchError, tap} from "rxjs/operators";
import {SsabRelatedDocumentList} from "../../model/document.model";


@Injectable({
  providedIn: 'root',
})
export class SsabOccUserClaimAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) {
  }

  public loadClaimDocuments(userId: string, params: SsabClaimRequest): Observable<SsabClaimList> {
    const url = this.occEndpoints.buildUrl('claimSearch', {urlParams: {userId}});
    return this.http.patch<SsabClaimList>(url, params);
  }

  public simulateClaimCreation(userId: string, params: SsabClaimCreateRequest): Observable<SsabClaim> {
    const url = this.occEndpoints.buildUrl('claimSimulate', {urlParams: {userId}});
    return this.http.patch<SsabClaim>(url, params);
  }

  public createClaim(userId: string, params: FormData): Observable<SsabClaim> {
    const url = this.occEndpoints.buildUrl('createClaim', {urlParams: {userId}});
    return this.http.post<SsabClaim>(url, params);
  }

  public getRelatedDocuments(claimId: string, orderId: string, customerId: string, complaintNumber?: string): Observable<SsabRelatedDocumentList> {
    const url = this.occEndpoints.buildUrl('relatedClaimDocuments', {urlParams: {claimId, orderId, customerId}, queryParams: {complaintNumber}});
    return this.http.get<SsabRelatedDocumentList>(url);
  }

  public changeStatus(claimId: string, data: SsabClaimStatusRequest): Observable<void> {
    const url = this.occEndpoints.buildUrl('changeClaimStatus', {urlParams: {claimId}});
    return this.http.patch<void>(url, data);
  }

  public changeAssignee(claimId: string, data: SsabClaimAssigneeRequest): Observable<void> {
    const url = this.occEndpoints.buildUrl('changeClaimAssignee', {urlParams: {claimId}});
    return this.http.patch<void>(url, data);
  }

  public sendMessage(claimId: string, message: string): Observable<SsabClaim> {
    const url = this.occEndpoints.buildUrl('sendClaimMessage', {urlParams: {claimId}});
    return this.http.patch<SsabClaim>(url, message);
  }

  public setClaimMessagesRead(claimId: string): Observable<SsabClaim> {
    const url = this.occEndpoints.buildUrl('setClaimMessagesRead', {urlParams: {claimId}});
    return this.http.patch<SsabClaim>(url, {});
  }
}
