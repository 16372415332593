import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {LAUNCH_CALLER, LaunchDialogService} from "@spartacus/storefront";
import {SsabScannerHowToScanDialogData} from "../how-to-scan/how-to-scan-layout.config";
import {SsabScannerDataValidationService} from "../../service/scanner/ssab-scanner-data-validation.service";

@Component({
  selector: 'scanner-home',
  templateUrl: './home.component.html',
})
export class ScannerHomeComponent {
  ocrResult: string = '';

  constructor(
    private router: Router,
    protected scannerDataValidationService: SsabScannerDataValidationService,
    protected launchDialogService: LaunchDialogService,) {
  }

  onScanMoreProducts() {
    this.router.navigate(['/scanner']);
  }

  handleInputValueChange(val: string) {
    const value = this.scannerDataValidationService.convertMaterialId(val);
    if (value?.length > 0) {
      this.router.navigate(['scanner/products'], {queryParams: {search: value}});
    }
  }

  openScannerHowToScanModal(): void {
    this.launchDialogService.closeDialog(null);
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.SCANNER_HOW_TO_SCAN, undefined, {} as SsabScannerHowToScanDialogData);
  }

  toggleInfo(event: MouseEvent): void {
    const alert = (event.target as HTMLElement).closest('.alert');
    alert.querySelector('.info-content').classList.toggle('d-none');
    alert.querySelector('.ssab-icon').classList.toggle('icon-rotate-180');
  }
}
