<div *ngIf="messages$ | async as messages">

  <ng-container *ngFor="let errorMsg of messages[messageType.MSG_TYPE_ERROR]; let i = index">
    <div
      class="alert alert-danger"
      *ngIf="validateDomIsRenderedAndMessageValid(errorMsg)"
    >
      <div class="container">
        <span [innerHTML]="errorMsg | cxTranslate | safeHtml"></span>
        <span class="ssab-icon icon-inline icon-white icon-system-info ml-2" role="button" *ngIf="isExtraInfoMessage(errorMsg.key)"
              placement="bottom"
              popoverClass="popover-sm popover-info" [ngbPopover]="alertMessageExtraContent"
              triggers="manual" (click)="popover.open()" #popover="ngbPopover"></span>

        <button
          class="close"
          type="button"
          (click)="clear(messageType.MSG_TYPE_ERROR, i)"
        >
          <span class="ssab-icon icon-white icon-navigation-close"></span>
        </button>
      </div>
    </div>

    <ng-template #alertMessageExtraContent>
      <div class="extra-content">
        <span [innerHTML]="getExtraInfoMessage(errorMsg.key) |cxTranslate | safeHtml"> </span>
      </div>
    </ng-template>
  </ng-container>

  <div
    class="alert alert-success"
    *ngFor="
      let confMsg of messages[messageType.MSG_TYPE_CONFIRMATION];
      let i = index
    "
  >
    <div class="container">
      <span [innerHTML]=" confMsg | cxTranslate | safeHtml "> </span>
      <button
        class="close"
        type="button"
        (click)="clear(messageType.MSG_TYPE_CONFIRMATION, i)"
      >
        <span class="ssab-icon icon-white icon-navigation-close"></span>
      </button>
    </div>
  </div>

  <div
    class="alert alert-info"
    *ngFor="let infoMsg of messages[messageType.MSG_TYPE_INFO]; let i = index"
  >
    <div class="container">
      <span [innerHTML]="infoMsg | cxTranslate | safeHtml"></span>
      <button
        class="close"
        type="button"
        (click)="clear(messageType.MSG_TYPE_INFO, i)"
      >
      <span class="ssab-icon icon-white icon-navigation-close"></span>
      </button>
    </div>
  </div>

  <div
    class="alert alert-warning"
    *ngFor="
      let infoMsg of messages[messageType.MSG_TYPE_WARNING]; let i = index"
  >
    <div class="container">
      <span [innerHTML]="infoMsg | cxTranslate | safeHtml"></span>
      <button
        class="close"
        type="button"
        (click)="clear(messageType.MSG_TYPE_WARNING, i)"
      >
        <span class="ssab-icon icon-white icon-navigation-close"></span>
      </button>
    </div>
  </div>
</div>
