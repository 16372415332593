<tr *ngIf="user$ | async as user">
  <td class="col-md-1 col-auto text-center">
    <button class="btn btn-icon my-0" (click)="toggleDetails()">
      <span class="ssab-icon icon-chevron-down" [ngClass]="{ 'icon-rotate-180': detailsOpen }"></span>
    </button>
  </td>
  <td class="col-md-1 col-auto">
    {{ claim.ssab8DDocumentId }}
  </td>
  <td class="col-md-1 col-auto">
    {{ claim.orderNumber }}
  </td>
  <td class="col-md-2 col-auto" *ngIf="user.internalUser">
    {{ claim.customer.name }}
  </td>
  <td class="col-md-2 col-auto">
    {{ claim.claimSubject }}
  </td>
  <td class="col-md-1 col-auto">
    {{ convertUIDate(claim.claimCreationDate)|cxDate: (getDateFormat()|async) }}
  </td>
  <td class="col-md-2 col-auto" *ngIf="user.internalUser">
    <div class="d-flex justify-content-between align-items-center">
      <span class="assignee-name">{{ claim.assignee?.name }}</span>
      <button class="btn btn-icon my-0" (click)="openChangeAssigneeModal()">
        <span class="ssab-icon icon-system-edit icon-sm"></span>
      </button>
    </div>
  </td>
  <td class="col-md-2 col-auto">
    <div class="d-flex justify-content-between align-items-center">
      <ng-container *ngIf="claim.claimStatus">
      <span class="claims-status" [ngClass]="claim.claimStatus ? 'status-'+claim.claimStatus : ''">
        <cx-icon [type]="iconTypes.CIRCLE" class="pr-1"></cx-icon>
        {{ 'ssab.claims.status.' + claim.claimStatus | cxTranslate }}
      </span>
      </ng-container>
      <button *ngIf="user.internalUser" class="btn btn-icon my-0" (click)="openChangeStatusModal()">
        <span class="ssab-icon icon-system-edit icon-sm"></span>
      </button>
    </div>
  </td>
  <td class="col-md-1 col-auto text-right">
    <button class="btn btn-icon my-0">
      <span class="ssab-icon icon-social-mail" (click)="openMessages(detailTypes.Messages)"></span>
      <span class="count" *ngIf="claim.unreadMessages > 0">
            {{ claim.unreadMessages }}
      </span>
    </button>
  </td>
</tr>
<tr *ngIf="detailsOpen">
  <td [attr.colspan]="9">
    <div class="cx-claims-details">
      <div class="cx-claims-details-tab">
        <h4 class="d-inline-block"
            [ngClass]="selectedDetail == detailTypes.Details ? 'selected': ''"
            (click)="changeDetailType(detailTypes.Details)">{{ 'ssab.claims.list.tab.details.title' | cxTranslate }}</h4>
        <h4 class="d-inline-block"
            [ngClass]="selectedDetail == detailTypes.Documents ? 'selected': ''"
            (click)="changeDetailType(detailTypes.Documents)">{{ 'ssab.claims.list.tab.documents.title' | cxTranslate }}</h4>
        <h4 class="d-inline-block"
            [ngClass]="selectedDetail == detailTypes.Messages ? 'selected': ''"
            (click)="changeDetailType(detailTypes.Messages)">
          {{ 'ssab.claims.list.tab.messages.title' | cxTranslate }}
          <span class="count" *ngIf="claim.unreadMessages > 0">
            {{ claim.unreadMessages }}
          </span>
        </h4>
      </div>

      <div [ngClass]="selectedDetail == detailTypes.Details ? 'd-block': 'd-none'">
        <ssab-cx-claim-request-form [initialClaim]="claim" [isRequestForm]="false"></ssab-cx-claim-request-form>
      </div>
      <div [ngClass]="selectedDetail == detailTypes.Documents ? 'd-block': 'd-none'">
        <div class="table-responsive">
          <table class="table cx-claims-table">
            <thead table-head
                   [label]="'ssab.claims.list.document.'"
                   [tableHeadType]="tableHeadType"
                   (selectAllDocuments)="checkAllDocuments($event)">
            </thead>
            <tbody>
            <ng-container *ngIf="documents$ | async as documentList">
              <ng-container
                *ngTemplateOutlet="docList; context: { documentList: documentList.claims, idkey: 'complaintNumber' }"></ng-container>
              <ng-container
                *ngTemplateOutlet="docList; context: { documentList: documentList.invoices, idkey: 'invoiceNumber' }"></ng-container>
            </ng-container>
            </tbody>
          </table>
        </div>
        <div class="pt-4 pb-4 text-right">
          <button class="btn btn-info btn-default-height mb-4 mb-md-0"
                  (click)="downloadDocumentsSelected()"
                  [disabled]="documentsSelectedToDownload && documentsSelectedToDownload.length == 0">
            <i class="ssab-icon icon-system-download icon-inline pr-2"
               [ngClass]="documentsSelectedToDownload && documentsSelectedToDownload.length == 0 ? 'icon-white': ''"></i>
            {{ 'ssab.documents.search.download.selected' | cxTranslate }}
          </button>
        </div>
      </div>
      <div [ngClass]="selectedDetail == detailTypes.Messages ? 'd-block': 'd-none'">
        <ssab-cx-claim-messages [claim]="claim"></ssab-cx-claim-messages>
      </div>
    </div>
  </td>
</tr>

<ng-template #docList let-documentList="documentList" let-idkey="idkey">
  <ng-container *ngFor="let document of documentList">
    <tr>
      <td class="col-md-1 col-auto">
        <div class="form-check mb-0 text-center">
          <input #selectedDocument
                 class="form-check-input position-relative"
                 type="checkbox" aria-label="..."
                 (change)="onChange(selectedDocument.checked, document)">
        </div>
      </td>
      <td class="col-md-1 col-auto text-center">
        <button class="btn btn-icon my-0" (click)="downloadSingleDocument(document)">
          <span class="ssab-icon icon-system-download"></span>
        </button>
      </td>
      <td class="col-md-2 col-auto">
        {{ ('ssab.document.documentType.' + document.documentType)| cxTranslate }}
      </td>
      <td class="col-md-2 col-auto">
        {{ document[idkey] }}
      </td>
      <td class="col-md-7 col-auto">
        {{ convertUIDate(document.documentDate)|cxDate: (getDateFormat()|async) }}
      </td>
    </tr>
  </ng-container>
</ng-template>
