import {SsabBannersModule} from './features/cms-components/banners/ssab-banners.module';
import {
  AnonymousConsentManagementBannerModule,
  AnonymousConsentsDialogModule,
  BannerCarouselModule,
  BannerModule,
  BreadcrumbModule,
  CategoryNavigationModule,
  CmsParagraphModule,
  ConsentManagementModule,
  FooterNavigationModule,
  HamburgerMenuModule,
  HomePageEventModule,
  LinkModule,
  LoginRouteModule,
  LogoutModule,
  MyCouponsModule,
  MyInterestsModule,
  NavigationEventModule,
  NavigationModule,
  NotificationPreferenceModule,
  PaymentMethodsModule,
  ProductCarouselModule,
  ProductDetailsPageModule,
  ProductFacetNavigationModule,
  ProductImagesModule,
  ProductIntroModule,
  ProductListingPageModule,
  ProductListModule,
  ProductPageEventModule,
  ProductReferencesModule,
  ProductSummaryModule,
  ProductTabsModule,
  SearchBoxModule,
  SiteContextSelectorModule,
  StockNotificationModule,
  TabParagraphContainerModule,
} from '@spartacus/storefront';
import {WishListModule} from '@spartacus/cart/wish-list';
import {SsabContactFormModule} from './features/service/contactform/ssab-contactform.module';
import {AnonymousConsentsModule, AuthModule, CostCenterOccModule, ExternalRoutesModule, I18nModule, ProductModule, ProductOccModule, UserModule, UserOccModule} from '@spartacus/core';
import {CartBaseOccModule} from '@spartacus/cart/base/occ';
import {CartBaseCoreModule} from '@spartacus/cart/base/core';
import {SsabOccEndpointsModule} from './features/service/endpoints/ssab-occ-endpoints.module';
import {SsabUserAuthModule} from './features/service/user-auth/ssab-user-auth.module';
import {SsabImpersonationModule} from './features/service/impersonation/ssab-impersonation.module';
import {SsabProductFacetNavigationModule} from './features/cms-components/product/product-list/product-facet-navigation/ssab-product-facet-navigation.module';
import {SsabI18nModule} from './features/shared/i18/ssab-i18n.module';
import {SsabRoutingModule} from './features/cms-structure/routing/ssab-routing.module';
import {SsabProductOccModule} from './features/service/product/ssab-product-occ.module';
import {SsabItemStepperModule} from './features/cms-components/product/product-list/item-stepper/ssab-item-stepper.module';
import {SsabCdcModule} from './features/service/cdc/ssab-cdc.module';
import {SsabNavigationUiModule} from './features/cms-components/navigation/navigation-ui/ssab-navigation-ui.module';
import {SsabOrderHistoryModule} from './features/cms-components/my-account/order/order-history/ssab-order-history.module';
import {SsabCartSharedModule} from './features/cms-components/cart/cart-shared/ssab-cart-shared.module';
import {SsabOrderDetailsModule} from './features/cms-components/order-history/details/ssab-order-details.module';
import {SsabContactSalesComponentModule} from './features/cms-components/cart/contactsales/ssab-contactsales.module';
import {SsabCartNotificationModule} from './features/cms-components/cart/cart-shared/cart-notification/ssab-cart-notification.module';
import {SsabSubscriptionsModule} from './features/service/subscription/ssab-subscriptions.module';
import {SsabCartDetailsModule} from './features/cms-components/cart/cart-details/ssab-cart-details.module';
import {NgModule} from '@angular/core';
import {SsabDocumentsSearchModule} from './features/cms-components/my-account/documents-search/ssab-documents-search.module';
import {SsabAsmComponentsModule} from './features/cms-components/asm/ssab-asm-components.module';
import {SsabContactSalesModule} from './features/service/contactsales/ssab-contactsales.module';
import {SsabProductSummaryModule} from './features/cms-components/product/product-summary/ssab-product-summary.module';
import {SsabGlobalMessageModule} from './features/cms-components/shared/global-message/ssab-global-message.module';
import {SsabUserOccModule} from './features/service/user/ssab-user-occ.module';
import {SsabAsmModule} from './features/asm/ssab-asm.module';
import {SsabCollectedItemsModule} from './features/cms-components/cart/contactsales/collected-items/ssab-collected-items.module';
import {SsabMyDetailsModule} from './features/cms-components/my-account/my-details/ssab-my-details.module';
import {SsabNotFoundModule} from './features/cms-components/shared/not-found/ssab-not-found.module';
import {SsabInputModule} from './features/shared/input/ssab-input.module';
import {SsabBannerModule} from './features/cms-components/banners/banner/ssab-banner.module';
import {SsabLoginModule} from './features/cms-components/login/ssab-login.module';
import {SsabMiniCartModule} from './features/cms-components/cart/minicart/ssab-mini-cart.module';
import {SsabPageSizeModule} from './features/cms-components/shared/page-size/ssab-page-size.module';
import {SsabAddToCartModule} from './features/cms-components/cart/add-to-cart/ssab-add-to-cart.module';
import {SsabBackgroundImageModule} from './features/cms-components/shared/background-image/ssab-background-image.module';
import {SsabMaterialDataModule} from './features/cms-components/my-account/material-data/ssab-material-data.module';
import {SsabSiteContextSelectorModule} from './features/cms-components/site-context-selector/ssab-site-context-selector.module';
import {SsabDelayedOrdersToggleModule} from './features/cms-components/my-account/delayed-orders/ssab-delayed-orders-toggle.module';
import {AsmFeatureModule} from './features/asm/asm-feature.module';
import {SmartEditFeatureModule} from './features/smartedit/smart-edit-feature.module';
import {SsabProductListModule} from './features/cms-components/product/product-list/ssab-product-list.module';
import {PersonalizationFeatureModule} from './features/tracking/personalization-feature.module';
import {SsabUserComponentModule} from './features/cms-components/user/ssab-user.module';
import {SsabGlobalconfigurationModule} from './features/service/general/configuration/ssab-globalconfiguration.module';
import {SsabCartOccModule} from './features/service/cart/ssab-cart-occ.module';
import {SsabSessionExpiredModule} from './features/cms-components/session-expired/ssab-session-expired.module';
import {SsabGtmModule} from './features/service/analytics/gtm/ssab-gtm.module';
import {SsabCartHeaderModule} from './features/cms-components/cart/cart-header/ssab-cart-header.module';
import {SsabCategoryNavigationModule} from './features/cms-components/navigation/category-navigation/ssab-category-navigation.module';
import {SsabSearchBoxModule} from './features/cms-components/navigation/search-box/ssab-search-box.module';
import {ProductVariantsFeatureModule} from './features/product/product-variants-feature.module';
import {SsabHamburgerMenuModule} from './features/cms-components/hamburger-menu/ssab-hamburger-menu.module';
import {SsabProductIntroModule} from './features/cms-components/product/product-intro/ssab-product-intro.module';
import {SsabAzureAuthenticationModule} from './features/shared/authentication/ssab-azure-authentication.module';
import {SsabNavigationModule} from './features/cms-components/navigation/navigation/ssab-navigation.module';
import {UserFeatureModule} from './features/user/user-feature.module';
import {SsabFooterContainerModule} from './features/cms-components/footer/footer-container/ssab-footer-container.module';
import {SsabDatepickerModule} from './features/cms-components/shared/datepicker/ssab-datepicker.module';
import {ProductImageZoomFeatureModule} from './features/product/product-image-zoom-feature.module';
import {OrderFeatureModule} from './features/order/order-feature.module';
import {OrganizationAdministrationFeatureModule} from './features/organization/organization-administration-feature.module';
import {CartQuickOrderFeatureModule} from './features/cart/cart-quick-order-feature.module';
import {CartImportExportFeatureModule} from './features/cart/cart-import-export-feature.module';
import {CheckoutFeatureModule} from './features/checkout/checkout-feature.module';
import {CartSavedCartFeatureModule} from './features/cart/cart-saved-cart-feature.module';
import {OrganizationOrderApprovalFeatureModule} from './features/organization/organization-order-approval-feature.module';
import {ProductConfiguratorFeatureModule} from './features/product-configurator/product-configurator-feature.module';
import {StoreFinderFeatureModule} from './features/storefinder/store-finder-feature.module';
import {ProductBulkPricingFeatureModule} from './features/product/product-bulk-pricing-feature.module';
import {SsabSiteContextOccModule} from './features/service/site-context/ssab-site-context-occ.module';
import {SsabConfirmationModule} from './features/cms-components/order-confirmation/confirmation/ssab-confirmation.module';
import {SsabAddToWishListModule} from './features/cms-components/cart/add-to-wishlist/ssab-add-to-wish-list.module';
import {SsabPreviousOrdersModule} from './features/cms-components/my-account/previous-orders/ssab-previous-orders.module';
import {SsabBaseModalModule} from './features/cms-components/modals/ssab-base-modal/ssab-base-modal.module';
import {SsabShowInchesToggleModule} from './features/cms-components/my-account/show-inches/ssab-show-inches-toggle.module';
import {UserProfileCoreModule} from '@spartacus/user/profile/core';
import {SsabScannerModule} from "./features/scanner/ssab-scanner.module";
import {SsabCoilsModule} from "./features/cms-components/coils-comparison/ssab-coils.module";
import {SsabClaimsModule} from "./features/cms-components/claims/ssab-claims.module";

@NgModule({
  declarations: [],
  imports: [
    // Auth Core
    AuthModule.forRoot(),
    LogoutModule,
    LoginRouteModule,
    // Basic Cms Components
    HamburgerMenuModule,
    SiteContextSelectorModule,
    LinkModule,
    BannerModule,
    CmsParagraphModule,
    TabParagraphContainerModule,
    BannerCarouselModule,
    CategoryNavigationModule,
    NavigationModule,
    FooterNavigationModule,
    BreadcrumbModule,
    // User Core,
    UserModule,
    UserOccModule,
    // User UI,
    PaymentMethodsModule,
    NotificationPreferenceModule,
    MyInterestsModule,
    StockNotificationModule,
    ConsentManagementModule,
    MyCouponsModule,
    // Anonymous Consents Core,
    AnonymousConsentsModule.forRoot(),
    // Anonymous Consents UI,
    AnonymousConsentsDialogModule,
    AnonymousConsentManagementBannerModule,
    // Product Core,
    ProductModule.forRoot(),
    ProductOccModule,
    // Product UI,
    ProductDetailsPageModule,
    ProductListingPageModule,
    ProductListModule,
    SearchBoxModule,
    ProductFacetNavigationModule,
    ProductTabsModule,
    ProductCarouselModule,
    ProductReferencesModule,
    ProductImagesModule,
    ProductSummaryModule,
    ProductIntroModule,
    // Cart Core,
    CartBaseCoreModule,
    CartBaseOccModule,
    // Cart UI,
    WishListModule,
    CostCenterOccModule,
    // Order,
    // OrderOccModule,
    // Page Events,
    NavigationEventModule,
    HomePageEventModule,
    ProductPageEventModule,
    // External routes,
    ExternalRoutesModule.forRoot(),
    UserFeatureModule,
    PersonalizationFeatureModule,
    StoreFinderFeatureModule,
    SmartEditFeatureModule,
    ProductConfiguratorFeatureModule,
    ProductBulkPricingFeatureModule,
    ProductVariantsFeatureModule,
    ProductImageZoomFeatureModule,
    OrganizationAdministrationFeatureModule,
    OrganizationOrderApprovalFeatureModule,
    OrderFeatureModule,
    CheckoutFeatureModule,
    CartSavedCartFeatureModule,
    CartQuickOrderFeatureModule,
    CartImportExportFeatureModule,
    AsmFeatureModule,
    UserProfileCoreModule
  ],
  exports: [
    SsabRoutingModule,
    SsabUserAuthModule,
    SsabAzureAuthenticationModule,
    SsabI18nModule,
    I18nModule,
    SsabProductListModule,
    SsabItemStepperModule,
    SsabProductOccModule,
    SsabCartOccModule,
    SsabAddToCartModule,
    SsabUserComponentModule,
    SsabFooterContainerModule,
    SsabCartHeaderModule,
    SsabLoginModule,
    SsabSiteContextSelectorModule,
    SsabCartSharedModule,
    SsabCartDetailsModule,
    SsabInputModule,
    SsabMiniCartModule,
    SsabSearchBoxModule,
    SsabDatepickerModule,
    SsabSearchBoxModule,
    SsabProductIntroModule,
    SsabProductSummaryModule,
    SsabOrderDetailsModule,
    SsabMyDetailsModule,
    SsabOrderDetailsModule,
    SsabHamburgerMenuModule,
    SsabNavigationUiModule,
    SsabCategoryNavigationModule,
    SsabMyDetailsModule,
    SsabUserOccModule,
    SsabProductFacetNavigationModule,
    SsabNavigationModule,
    SsabOrderHistoryModule,
    SsabBackgroundImageModule,
    SsabAsmModule,
    SsabGlobalMessageModule,
    SsabGtmModule,
    SsabBannersModule,
    SsabPageSizeModule,
    SsabDocumentsSearchModule,
    SsabSessionExpiredModule,
    SsabImpersonationModule,
    SsabSubscriptionsModule,
    SsabCdcModule,
    SsabCollectedItemsModule,
    SsabContactFormModule,
    SsabContactSalesModule,
    SsabContactSalesComponentModule,
    SsabGlobalconfigurationModule,
    SsabMaterialDataModule,
    SsabNotFoundModule,
    SsabBannerModule,
    SsabOccEndpointsModule,
    SsabAsmComponentsModule,
    SsabDelayedOrdersToggleModule,
    SsabCartNotificationModule,
    SsabSiteContextOccModule,
    SsabConfirmationModule,
    SsabPreviousOrdersModule,
    SsabAddToWishListModule,
    SsabBaseModalModule,
    SsabShowInchesToggleModule,
    SsabScannerModule,
    SsabCoilsModule,
    SsabClaimsModule
  ]
})
export class SpartacusFeaturesModule {
}
