import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ICON_TYPE, LAUNCH_CALLER, LaunchDialogService} from "@spartacus/storefront";
import {GlobalMessageService, GlobalMessageType} from "@spartacus/core";
import {Observable, Subscription} from 'rxjs';
import {SsabChangeStatusDialogData} from "./change-status-dialog-layout.config";
import {ClaimStatusTypes, SsabClaim, SsabClaimDocument, SsabClaimStatusRequest} from "../../../model/claim.mode";
import {SsabUserService} from "../../../service/user/ssab-user.service";
import {map, filter} from 'rxjs/operators';
import {DateUtils} from "../../../shared/util/date-utils";
import {SsabGlobalconfigurationService} from "../../../service/general/configuration/ssab-globalconfiguration.service";
import {DocumentTypes, SsabDocumentIdentifier} from "../../../model/document.model";
import {Router} from "@angular/router";

@Component({
  selector: 'ssab-cx-change-status-dialog',
  templateUrl: './change-status-dialog.component.html',
})
export class ChangeStatusDialogComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  iconTypes = ICON_TYPE;
  allowedStatuses = [
    ClaimStatusTypes.submitted,
    ClaimStatusTypes.inProcess,
    ClaimStatusTypes.evaluated,
    ClaimStatusTypes.completed,
    ClaimStatusTypes.rejected,

  ]
  currentStatus: string;
  subscriptions: Subscription = new Subscription();

  selectedStatus: string;
  showInProcess: boolean = false;
  showEvaluated: boolean = false;
  showCompleted: boolean = false;
  showRejected: boolean = false;
  showSubmitted: boolean = false;
  showEvaluatedDocument: boolean = false;
  claim: SsabClaim;

  documents$: Observable<SsabClaimDocument[]>;

  constructor(protected fb: UntypedFormBuilder,
              private globalConfigurationService: SsabGlobalconfigurationService,
              protected launchDialogService: LaunchDialogService,
              protected globalMessageService: GlobalMessageService,
              protected userService: SsabUserService,
              protected router: Router,
              protected cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.launchDialogService.data$.subscribe((dialogData: SsabChangeStatusDialogData) => {
        if (dialogData) {
          this.claim = dialogData.claim;
          this.currentStatus = dialogData.currentStatus;
          this.selectedStatus = this.currentStatus;
          if (this.currentStatus === ClaimStatusTypes.submitted) {
            this.selectedStatus = ClaimStatusTypes.inProcess;
          } else if (this.currentStatus === ClaimStatusTypes.inProcess && this.claim.ssab8DDocumentId) {
            this.selectedStatus = ClaimStatusTypes.evaluated;
          } else if (this.currentStatus === ClaimStatusTypes.evaluated) {
            this.selectedStatus = ClaimStatusTypes.completed;
          }
          this.changeStatus(this.selectedStatus);
          this.documents$ = this.userService.getRelatedClaimDocuments(this.claim.code, this.claim.orderNumber, this.claim.customer?.uid, this.claim.ssab8DDocumentId)
            .pipe(
              map(d => d.claims.filter(d => d.docType !== DocumentTypes.ClaimAttachment))
            );
          this.form = this.fb.group(
            {
              claimId: [this.claim.ssab8DDocumentId, [Validators.required]],
              newStatus: [this.selectedStatus, [Validators.required]],
              message: ['', []],
              attach: [this.claim.ssab8DDocumentLinked, []],
            }
          );

        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  changeStatus(status) {
    this.selectedStatus = status;
    this.showInProcess = status === ClaimStatusTypes.inProcess;
    this.showEvaluated = status === ClaimStatusTypes.evaluated;
    this.showCompleted = status === ClaimStatusTypes.completed;
    this.showRejected = status === ClaimStatusTypes.rejected;
    this.showSubmitted = status === ClaimStatusTypes.submitted;
  }

  searchDocument() {
    this.showEvaluatedDocument = true
  }

  update() {
    if (!this.form.value.newStatus) {
      this.globalMessageService.add(
        {
          key: 'ssab.claim.request.error.status',
        },
        GlobalMessageType.MSG_TYPE_ERROR
      );
      return;
    }
    if (!this.form.valid)  {
      this.globalMessageService.add(
        {
          key: 'ssab.claim.request.submitError',
        },
        GlobalMessageType.MSG_TYPE_ERROR
      );
      const control = this.form.get("claimId");
      if (control) {
        control.markAsTouched();
      }
      return;
    }
    this.userService.updateClaimStatus(
      this.claim.code,
      {
        complaintNumber: this.form.value.claimId,
        status: this.form.value.newStatus,
        messageToCustomer: this.form.value.message,
        linkToClaim: this.form.value.attach === true
      } as SsabClaimStatusRequest
    ).then(() => {
      this.claim.claimStatus = this.form.value.newStatus;
      this.dismiss('updated');
      this.globalMessageService.add(
        {
          key: 'ssab.claim.request.status',
        },
        GlobalMessageType.MSG_TYPE_CONFIRMATION
      );
    });
  }

  public dismiss(reason: any = 'closed'): void {
    this.launchDialogService.clear(LAUNCH_CALLER.CHANGE_STATUS);
    this.launchDialogService.closeDialog(reason);
  }

  downloadClaimDocument(documentToDownload: SsabDocumentIdentifier): void {
    this.userService.downloadClaimDocument(documentToDownload);
  }

  convertUIDate(d: string): Date {
    return DateUtils.convertDate(d);
  }

  getDateFormat(): Observable<string> {
    return this.globalConfigurationService.getDateFormat();
  }
}
