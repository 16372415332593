var productImageZoomTrigger = {
  expand: "Expand image"
};
var productImageZoomDialog = {
  close: "Close"
};
var productImageZoom = {
  productImageZoomTrigger: productImageZoomTrigger,
  productImageZoomDialog: productImageZoomDialog
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  productImageZoom
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const productImageZoomTranslations = {
  en
};
// expose all translation chunk mapping for imageZoom feature
const productImageZoomTranslationChunksConfig = {
  productImageZoom: ['productImageZoomTrigger', 'productImageZoomDialog']
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * Generated bundle index. Do not edit.
 */

export { productImageZoomTranslationChunksConfig, productImageZoomTranslations };
