import {createAction, Store} from '@ngrx/store';
import {SsabAuthService} from '../../../service/user-auth/ssab-auth.service';
import {Component, ElementRef, Inject, OnDestroy, OnInit} from '@angular/core';
import {GlobalLogin, SsabBaseSite} from '../../../model/misc.model';
import {Observable, of, Subscription} from 'rxjs';
import {AuthService, BaseSiteService, CmsService, Page, SiteConnector, SiteContextActions, WindowRef} from '@spartacus/core';
import {filter, map, take, withLatestFrom} from 'rxjs/operators';
import {AsmEnablerService} from '@spartacus/asm/root';
import {MsalService} from '../../../shared/authentication';
import {LoginFormComponent, LoginFormComponentService} from '@spartacus/user/account/components';
import {Router} from '@angular/router';
import {SsabCdcConfig} from "../../../service/cdc/ssab-cdc-config";
import {SsabCdcService} from "../../../service/cdc/ssab-cdc.service";
import {SsabAuthRedirectService} from "../../../service/user-auth/ssab-auth-redirect.service";
import {DOCUMENT} from "@angular/common";


@Component({
  selector: 'ssab-cx-login-form',
  templateUrl: './ssab-login-form.component.html',
})
export class SsabLoginFormComponent extends LoginFormComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription();

  constructor(
    private aAuth: SsabAuthService,
    private aWinRef: WindowRef,
    private authService: MsalService,
    private baseSiteService: BaseSiteService,
    protected siteConnector: SiteConnector,
    protected asmEnablerService: AsmEnablerService,
    protected store: Store,
    protected router: Router,
    protected elementRef: ElementRef<HTMLElement>,
    protected cdcService: SsabCdcService,
    protected service: LoginFormComponentService,
    protected cmsService: CmsService,
    protected cdcConfig: SsabCdcConfig,
    protected ssabAuthRedirectService: SsabAuthRedirectService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(service);

  }

  ngOnInit(): void {
    this.baseSiteService
      .getActive()
      .pipe(filter(Boolean), take(1))
      .subscribe((baseSite) => {
        if (baseSite !== GlobalLogin.GlobalBaseSite) {
          this.ssabAuthRedirectService.handleStoreSelection(GlobalLogin.GlobalBaseSite, false, false);
        }
      }).unsubscribe();

    if (this.isAsmLaunchedButNotVisible()) {
      this.showAsmUiAndReinitCloseButton();
    }
    this.aAuth.setAzureLoginInProgress(false);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /*
  * the problem is as following: asm main ui is rendered only on page refresh (manual url rewrite in the browser with ?asm=true)
  * then spartacus shows (means unhides) main asm ui on angular app initialization. if to do redirect via angular router, this doesnot trigger
  * app and components reinialization so asm mode is activated but asm ui is not displayed. so this method is to check if the ui is not visible
  * though asm mode is started/initialized
  */
  private isAsmLaunchedButNotVisible(): boolean {
    const params = this.aWinRef.location.toString().split('?')[1];
    const asmShouldBeLaunched = params && params.split('&').includes('asm=true');
    if (asmShouldBeLaunched) {
      this.asmEnablerService.load();
    }
    return asmShouldBeLaunched && this.asmEnablerService.isEnabled()
      && this.document.getElementsByTagName('cx-asm-main-ui')
      && this.document.getElementsByTagName('cx-asm-main-ui').length > 0
      && this.document.getElementsByTagName('cx-asm-main-ui')[0].getAttribute('class') == 'hidden';
  }

  private showAsmUiAndReinitCloseButton(): void {
    // asm main ui is shown by default only when initializing the app / force refreshing the page
    this.document.getElementsByTagName('cx-asm-main-ui')[0].setAttribute('class', '');
    // and once it is displayed not the way sap wants it, it can not be hidden anymore - so reinitialize the button to hide asm main ui on close
    let element: HTMLElement = this.document.getElementsByTagName('cx-asm-main-ui')[0].getElementsByClassName('close')[0] as HTMLElement;
    element.addEventListener('click', (e: Event) => this.document.getElementsByTagName('cx-asm-main-ui')[0].setAttribute('class', 'hidden'));
  }

  public startAzureLogin(): void {
    this.authService.startAzureLogin();
  }

  public isAzureLoginActive(): Observable<boolean> {
    return this.baseSiteService.get().pipe(
      map((baseSiteData: SsabBaseSite) => baseSiteData.azureLogin)
    );
  }

  public isAzureLoginActiveAndNoASM(): Observable<boolean> {
    if (!this.asmEnablerService.isEnabled()) {
      return this.isAzureLoginActive();
    } else {
      return of(false);
    }
  }

  public isCdcLoginActive(): Observable<boolean> {
    return this.baseSiteService.get().pipe(
      map((baseSiteData: SsabBaseSite) => baseSiteData.cdcLogin)
    );
  }

  public isSSOLoginActive(): Observable<boolean> {
    return this.aAuth.isSSOLoginActive();
  }

  public isResetPasswordPage(): Observable<boolean> {
    return this.cmsService.getCurrentPage().pipe(
      map((page: Page) => {
        if (page !== undefined && page.pageId === 'passwordreset') {
          return true;
        } else {
          return false;
        }
      })
    );

  }

  public isCommerceLoginActive(): Observable<boolean> {
    return this.baseSiteService.get().pipe(
      map((baseSiteData: SsabBaseSite) => baseSiteData.commerceLogin)
    );
  }

  public isAzureLoginInProgressAndNoASM(): boolean {
    return this.aAuth.isAzureLoginInProgress() && !this.asmEnablerService.isEnabled();
  }

  public isAzureLoginInProgress(): boolean {
    return this.aAuth.isAzureLoginInProgress();
  }

  public checkSubmitForm(): void {

    this.onSubmit();
    this.subscriptions.add(
      this.isUserLoggedIn().subscribe(
        isLoggedIn => {
          if (!isLoggedIn) {
            this.document.getElementById('login-error')?.classList.remove('d-none');
          } else {
            this.reloadCurrencies();
          }
        })
    );
  }

  protected reloadCurrencies(): void {
    const reloadCurrencies = createAction(SiteContextActions.LOAD_CURRENCIES_SUCCESS, (payload: any) => ({payload: payload}));
    this.subscriptions.add(
      this.siteConnector.getCurrencies().subscribe(curr => {
        const ev = reloadCurrencies(curr);
        this.store.dispatch(ev);
      })
    );
  }

  isAllLoginMethodsLoaded(): Observable<boolean> {
    return this.cdcService.cdcRendered.pipe(
      withLatestFrom(this.isCdcLoginActive(), this.isResetPasswordPage(), this.isUserLoggedIn(), this.aAuth.isAzureLoginInProcess(), this.isSSOLoginActive()),
      map(([cdcRendered, isCdc, resetPw, userLoggedIn, azureLoginInProcess, ssoLogin]) =>
        (this.asmEnablerService.isEnabled() || !userLoggedIn) && (!isCdc || cdcRendered || resetPw) && !azureLoginInProcess && !ssoLogin
      )
    );
  }

  isUserLoggedIn(): Observable<boolean> {
    return this.aAuth.isUserLoggedIn();
  }

  public getCdcLoginContainerId(): string {
    return this.cdcConfig.cdc.screenSets.login.containerId;
  }
}
