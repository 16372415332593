import {DIALOG_TYPE, LayoutConfig} from '@spartacus/storefront';
import {ChangeStatusDialogComponent} from "./change-status-dialog.component";
import {SsabClaim} from "../../../model/claim.mode";

declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    CHANGE_STATUS = 'CHANGE_STATUS',
  }
}

export const changeStatusLayout: LayoutConfig = {
  launch: {
    CHANGE_STATUS: {
      inlineRoot: true,
      component: ChangeStatusDialogComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};

export interface SsabChangeStatusDialogData {
  currentStatus?: string;
  claim: SsabClaim;
}
