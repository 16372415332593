import {ChangeDetectionStrategy, Component} from '@angular/core';
import {CmsComponentData, ICON_TYPE, SearchBoxComponent} from '@spartacus/storefront';
import {SsabSearchBoxComponentService} from "../../../service/product/search/ssab-search-box-component.service";
import {CmsSearchBoxComponent, RoutingService, WindowRef} from '@spartacus/core';
import {map, switchMap, tap} from 'rxjs/operators';
import {SsabSearchBoxConfig} from "../../../model/misc.model";
import {of} from 'rxjs';

const DEFAULT_SEARCH_BOX_CONFIG: SsabSearchBoxConfig = {
  minCharactersBeforeRequest: 1,
  displayProducts: true,
  displaySuggestions: true,
  maxProducts: 5,
  maxSuggestions: 5,
  displayProductImages: true,
  recentSearches: true,
  maxRecentSearches: 5,
  //trendingSearches: true,
  // maxTrendingSearches: 5,
};

@Component({
  selector: 'ssab-cx-searchbox',
  templateUrl: './ssab-search-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabSearchBoxComponent extends SearchBoxComponent {
  iconTypes = ICON_TYPE;

  constructor(
    protected searchBoxComponentService: SsabSearchBoxComponentService,
    protected componentData: CmsComponentData<CmsSearchBoxComponent>,
    protected winRef: WindowRef,
    protected routingService: RoutingService
  ) {
    super(searchBoxComponentService, componentData, winRef, routingService);
    this.config$ = (
      this.componentData?.data$ || of({} as any)
    ).pipe(
      map((config) => {
        const isBool = (obj: SsabSearchBoxConfig, prop: string): boolean =>
          obj &&
          obj[prop as keyof SsabSearchBoxConfig] !== 'false' &&
          obj[prop as keyof SsabSearchBoxConfig] !== false;

        return {
          ...DEFAULT_SEARCH_BOX_CONFIG,
          ...config,
          displayProducts: isBool(config, 'displayProducts'),
          displayProductImages: isBool(config, 'displayProductImages'),
          displaySuggestions: isBool(config, 'displaySuggestions'),
          // we're merging the (optional) input of this component, but write the merged
          // result back to the input property, as the view logic depends on it.
          ...this.config,
        };
      }),
      tap((config) => (this.config = config))
    );
    this.results$ = this.config$.pipe(
      switchMap((config) => this.searchBoxComponentService.getResults(config))
    );

    /* this.items$ = this.results$.pipe(
       map((result) => result.products?.map((prod) => of(prod)))
     );*/
  }

  searchMinCharacters(query: string): void {
    if (query.length >= this.config.minCharactersBeforeRequest) {
      this.search(query);
    }
  }

  clearAndClose(event: UIEvent, el: HTMLInputElement) {
    this.close(event, true);
    this.clear(el);
  }

  // overridden method to not check query length before firing search
  launchSearchResult(event: UIEvent, query: string): void {
    this.close(event);
    this.searchBoxComponentService.launchSearchPage(query);
  }

  onlyClear(el: HTMLInputElement): void {
    el.value = '';
    this.searchBoxComponentService.clearResults();
  }
}
