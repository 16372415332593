import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConverterService, OccEndpointsService, OccProductSearchAdapter, PRODUCT_SEARCH_PAGE_NORMALIZER, ProductSearchPage, SearchConfig} from "@spartacus/core";
import {SsabSearchConfig} from "../../../model/misc.model";
import {map, switchMap} from 'rxjs/operators';
import {SsabActiveCartService} from "../../cart/ssab-active-cart.service";

@Injectable()
export class SsabProductSearchAdapter extends OccProductSearchAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    protected activeCartService: SsabActiveCartService,
  ) {
    super(http, occEndpoints, converter);
  }

  search(
    query: string,
    searchConfig: SearchConfig = this.DEFAULT_SEARCH_CONFIG
  ): Observable<ProductSearchPage> {
    // this breaks ASM
    /*   const context = new HttpContext().set(OCC_HTTP_TOKEN, {
         sendUserIdAsHeader: true,
       });*/

    return this.getSearchEndpoint2(query, searchConfig)
      .pipe(
        switchMap(url => this.http.get(url, {/*context*/})
          .pipe(this.converter.pipeable(PRODUCT_SEARCH_PAGE_NORMALIZER))
        )
      );
  }

  protected getSearchEndpoint2(query: string, searchConfig: SsabSearchConfig): Observable<string> {
    let url = super.getSearchEndpoint(query, searchConfig);
    if (searchConfig.suggestiveMode) {
      url += '&suggestiveMode=true';
    }
    if (searchConfig.disableSpinner) {
      url = url.replace('&spinner=true', '');
    }
    return this.activeCartService.getActive()
      .pipe(
        map(() => url)
      );
  }

}
