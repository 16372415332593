import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, distinctUntilChanged, filter, map, switchMap, take, withLatestFrom} from 'rxjs/operators';
import {AuthActions, CmsService, EventService, GlobalMessageActions, GlobalMessageEntities, GlobalMessageService, GlobalMessageType, LoggerService, normalizeHttpError, ProductActions, RoutingService, SiteContextActions, Translatable, UserIdService,} from '@spartacus/core';
import {Observable, of, zip} from 'rxjs';
import {CREDIT_CHECK_ACTION, CreditCheckCompleteAction, CreditCheckPerformAction, RELOAD_CURRENT_USER_REQUEST, ReloadCurrentUserRequest, ReloadCurrentUserRequestSuccess, SetShowInches, SetShowInchesFail, SetShowInchesSuccess, SHOW_INCHES, StoreLanguageChangeSuccess, SUBSCRIBE_LATE_ORDER_REQUEST, SUBSCRIBE_LATE_ORDER_REQUEST_SUCCESS, SubscribeLateOrderRequest, SubscribeLateOrderRequestFail, SubscribeLateOrderRequestSuccess} from './ssab-user.action';
import {SsabUserProfileConnector} from './ssab-user-profile.connector';
import {SsabCart} from '../../model/cart.model';
import {SsabAlertService} from '../general/ssab-alert.service';
import {SsabUserService} from './ssab-user.service';
import {UserAccountChangedEvent} from '@spartacus/user/account/root';
import {CartActions, CartConnector} from '@spartacus/cart/base/core';
import {SsabActiveCartService} from '../cart/ssab-active-cart.service';
import {SsabProductListComponentService} from '../product/ssab-product-list-component.service';
import {AsmEnablerService} from '@spartacus/asm/root';

@Injectable()
export class SsabUserEffects {
  readonly CREDIT_CHECK_MESSAGE_PREFIX = 'ssab.creditcheck.notification.';
  reloadInternalUserAccount$: Observable<ReloadCurrentUserRequestSuccess>;
  resetCartDetailsOnSubscribeLateOrderChange$: Observable<CartActions.ResetCartDetails>;
  creditCheckOnLogin$: Observable<CreditCheckPerformAction>;
  handleCreditCheckEvent$: Observable<GlobalMessageActions.AddMessage | GlobalMessageActions.RemoveMessage | CreditCheckCompleteAction>;
  removeCreditCheckNotificationsOnLogout$: Observable<GlobalMessageActions.RemoveMessage | CreditCheckCompleteAction>;
  requestSubscribeLateOrder$: Observable<| SubscribeLateOrderRequestFail
    | GlobalMessageActions.AddMessage
    | SubscribeLateOrderRequestSuccess>;
  setShowInches$: Observable<| SetShowInchesFail
    | ReloadCurrentUserRequest
    | GlobalMessageActions.AddMessage
    | SetShowInchesSuccess
    | ProductActions.SearchProducts>;
  storeLanguageChanged$: Observable<any>;

  constructor(
    private actions$: Actions,
    private globalMessageService: GlobalMessageService,
    private userProfileConnector: SsabUserProfileConnector,
    protected activeCartService: SsabActiveCartService,
    protected cartConnector: CartConnector,
    protected alertService: SsabAlertService,
    protected cmsService: CmsService,
    protected userService: SsabUserService,
    protected eventService: EventService,
    protected userIdService: UserIdService,
    protected asmEnablerService: AsmEnablerService,
    protected routing: RoutingService,
    protected productListComponentService: SsabProductListComponentService,
    protected logger: LoggerService
  ) {

    this.creditCheckOnLogin$ = createEffect(() => this.actions$.pipe(
      ofType(
        //SiteContextActions.LOAD_LANGUAGES,
        CartActions.LOAD_CART_SUCCESS
      ),
      switchMap(() =>
        [new CreditCheckPerformAction({stickyNotification: true})]
      ) // forceReloadCart true because all carts on login are not calculated
    ));


    this.removeCreditCheckNotificationsOnLogout$ = createEffect(() => this.actions$.pipe(
      ofType(AuthActions.LOGOUT),
      switchMap(() => this.globalMessageService.get().pipe(take(1), map(e => this.findCreditCheckMessageIndex(e)))),
      switchMap(ind => {
        if (ind > -1) {
          return [new GlobalMessageActions.RemoveMessage({type: GlobalMessageType.MSG_TYPE_ERROR, index: ind})];
        }
        this.alertService.clear();
        // dummy action but in order to NOT go into infinite loop, effect must return an action
        return [new CreditCheckCompleteAction()];
      })
    ));


    this.handleCreditCheckEvent$ = createEffect(() => this.actions$.pipe(
      ofType(CREDIT_CHECK_ACTION),
      concatMap((action: CreditCheckPerformAction) => zip(
          this.activeCartService.getActive().pipe(
            filter(cart => cart.code != null),
            switchMap(cart =>
              action.payload.forceReloadCart ? this.cartConnector.load(cart.user.uid, cart.code) as Observable<SsabCart> : of(cart))
          ),
          of(action),
          this.globalMessageService.get().pipe(
            take(1),
            map(e =>
              this.findCreditCheckMessageIndex(e, action.payload.customMessageKey))
          )
        )
      ),
      concatMap(([cart, action, messageIndex]) => {
        const shouldDisplayMessage = cart.code != null && (cart.creditBlocked || cart.creditPrepaymentRequired || cart.salesBlocked || cart.paymentTermBlock || cart.showB1CreditMessageHeader);
        const actions = [];
        if (action.payload.clearOldNotifications) {
          actions.push(new GlobalMessageActions.RemoveMessage({type: GlobalMessageType.MSG_TYPE_ERROR, index: messageIndex}));
        }
        if (shouldDisplayMessage) {
          const messageKey = action.payload.customMessageKey ? action.payload.customMessageKey : this.selectAlertMessage(cart);
          if (action.payload.stickyNotification) {
            const allowCloseAlert = this.CREDIT_CHECK_MESSAGE_PREFIX.concat("paymentTermBlock.content") !== messageKey ? true : false;
            const extraInfoMessage = this.CREDIT_CHECK_MESSAGE_PREFIX.concat("creditBlockedB1.content") === messageKey ? this.CREDIT_CHECK_MESSAGE_PREFIX.concat("creditBlockedB1.extraContent") : '';
            this.alertService.error(messageKey, {id: 'alert-creditCheck', allowClose : allowCloseAlert, extraInfoMessage : extraInfoMessage ,autoClose: false, hidePages: ['/cart']});
          } else {
            if (messageIndex === -1) {
              actions.push(new GlobalMessageActions.AddMessage({type: GlobalMessageType.MSG_TYPE_ERROR, text: {key: messageKey}, timeout: 60000}));
            }
          }
        } else {
          this.alertService.clear();
        }
        if (actions.length === 0) {
          actions.push(new CreditCheckCompleteAction()); // dummy action but in order to NOT go into infinite loop, effect must return an action
        }
        return actions;
      })
    ));

    this.requestSubscribeLateOrder$ = createEffect(() => this.actions$.pipe(
      ofType(SUBSCRIBE_LATE_ORDER_REQUEST),
      map((action: SubscribeLateOrderRequest) => action.payload),
      concatMap((payload) => {
        return this.userProfileConnector
          .delayedOrderSubscription(payload.userId, payload.isLateOrderSubscription)
          .pipe(
            switchMap(() => [
              new SubscribeLateOrderRequestSuccess(payload.userId),
              new GlobalMessageActions.AddMessage({
                text: {key: 'ssab.order.history.delayed.orders.success'},
                type: GlobalMessageType.MSG_TYPE_INFO,
              }),
            ]),
            catchError((error) =>
              of(
                new SubscribeLateOrderRequestFail(
                  {userId: payload.userId, error: normalizeHttpError(error, this.logger)})
              )
            )
          );
      })
    ));

    this.setShowInches$ = createEffect(() => this.actions$.pipe(
      ofType(SHOW_INCHES),
      map((action: SetShowInches) => action.payload),
      withLatestFrom(this.userIdService.getUserId(), this.routing.getRouterState()),
      concatMap(([payload, userId, route]) => {
        return this.userProfileConnector
          .setShowInches(userId, payload.isShowInches)
          .pipe(
            switchMap(() => {
              const actions = [];
              actions.push(new SetShowInchesSuccess(userId));
              actions.push(
                new GlobalMessageActions.AddMessage({
                  text: {key: 'ssab.user.showInches.update.success'},
                  type: GlobalMessageType.MSG_TYPE_INFO,
                })
              );
              actions.push(new ReloadCurrentUserRequest());

              if (payload.refreshPLP) {
                const routeCriteria = this.productListComponentService.getCriteriaFromRoute(
                  route.state.params,
                  route.state.queryParams,
                );
                actions.push(new ProductActions.SearchProducts({
                  queryText: routeCriteria.query,
                  searchConfig: Object.assign(
                    {},
                    routeCriteria.currentPage ? {currentPage: routeCriteria.currentPage} : {},
                    routeCriteria.previousPageSize ? {previousPageSize: routeCriteria.previousPageSize} : {},
                    routeCriteria.pageSize ? {pageSize: routeCriteria.pageSize} : {},
                    routeCriteria.sortCode ? {sort: routeCriteria.sortCode} : {}
                  ),
                }));
              }
              return actions;
            }),
            catchError((error) =>
              of(
                new SetShowInchesFail(
                  {userId, error: normalizeHttpError(error, this.logger)})
              )
            )
          );
      })
    ));


    this.storeLanguageChanged$ = createEffect(() => this.actions$.pipe(
      ofType(SiteContextActions.LANGUAGE_CHANGE),
      filter(action => !asmEnablerService.isEnabled()),
      map(action => action['payload']),
      concatMap((payload: any) => zip(
        of(payload),
        this.userService.get()
      )),
      concatMap(([payload, user]) => {
        return this.userProfileConnector
          .storeSessionLanguage(user.uid)
          .pipe(
            map(() =>
              new StoreLanguageChangeSuccess(user.uid)
            )
          );
      })
    ));


    this.resetCartDetailsOnSubscribeLateOrderChange$ = createEffect(() => this.actions$.pipe(
      ofType(
        SUBSCRIBE_LATE_ORDER_REQUEST_SUCCESS
      ),
      concatMap((payload: SubscribeLateOrderRequestSuccess) => zip(
        of(payload),
        this.userService.getUserDetails()
      )),
      concatMap(([payload, user]) => {
        this.eventService.dispatch(
          {user},
          UserAccountChangedEvent
        );
        return [new CartActions.ResetCartDetails()];
      })
    ));


    this.reloadInternalUserAccount$ = createEffect(() => this.actions$.pipe(
      ofType(
        RELOAD_CURRENT_USER_REQUEST
      ),
      concatMap((action) => zip(
        of(action),
        this.userService.getUserDetails()
      )),
      distinctUntilChanged(),
      switchMap(([action, user]) => {
        this.eventService.dispatch(
          {user},
          UserAccountChangedEvent
        );
        return [new ReloadCurrentUserRequestSuccess()];
      })
    ));
  }

  findCreditCheckMessageIndex(entities: GlobalMessageEntities, customKey ?: string): number {
    // cart page and global credit check message show same text for now but these texts are different keys, so do not show 2 messages at a time
    const ind = entities[GlobalMessageType.MSG_TYPE_ERROR]?.findIndex((message: Translatable) =>
      (message.key as string).startsWith(this.CREDIT_CHECK_MESSAGE_PREFIX) || (customKey && (message.key as string).startsWith(customKey)));
    return ind != null ? ind : -1;
  }

  selectAlertMessage(cart: SsabCart): string {
    if (cart.paymentTermBlock) {
      return this.CREDIT_CHECK_MESSAGE_PREFIX + 'paymentTermBlock' + '.content';
    }
    if (cart.creditPrepaymentRequired) {
      return this.CREDIT_CHECK_MESSAGE_PREFIX + 'creditPrepaymentRequired' + '.content';
    }
    if (cart.showB1CreditMessageHeader) {
      return this.CREDIT_CHECK_MESSAGE_PREFIX + 'creditBlockedB1' + '.content';
    }
    else {
      return this.CREDIT_CHECK_MESSAGE_PREFIX + 'creditBlocked' + '.content';
    }
  }

}
