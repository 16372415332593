<ng-container *ngFor="let alert of alerts">
  <div class="{{cssClass(alert)}}" [ngClass]="{'d-none': hideAlert(alert)}">
    <div class="container">
      <span class="alert-icon" *ngIf="!extraInfoMessage(alert)">
        <cx-icon [type]="iconTypes.ERROR"></cx-icon>
      </span>
      <span [innerHTML]="alert.message | cxTranslate | safeHtml"></span>
      <ng-container *ngIf="allowClose(alert)">
        <button type="button" class="close" (click)="removeAlert(alert)">
          <span class="ssab-icon icon-white icon-navigation-close"></span>
        </button>
        <span class="ssab-icon icon-inline icon-white icon-system-info ml-2" role="button" *ngIf="extraInfoMessage(alert)"
              placement="bottom"
              popoverClass="popover-sm popover-info" [ngbPopover]="alertMessageExtraContent"
              triggers="manual" (click)="popover.open()" #popover="ngbPopover"></span>
      </ng-container>
    </div>
  </div>

  <ng-template #alertMessageExtraContent>
    <div class="extra-content">
      <span [innerHTML]="alert.extraInfoMessage |cxTranslate | safeHtml"> </span>
    </div>
  </ng-template>

</ng-container>
