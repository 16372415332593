import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators,} from "@angular/forms";
import {LAUNCH_CALLER, LaunchDialogService} from "@spartacus/storefront";
import {GlobalMessageService, GlobalMessageType, LanguageService, TranslationService} from "@spartacus/core";
import {ClaimAssigneeTypes, SsabClaim, SsabClaimAssigneeRequest} from "../../../model/claim.mode";
import {UserGroup} from "@spartacus/organization/administration/core";
import {BehaviorSubject, Observable, Subscriber, Subscription} from 'rxjs';
import {SsabChangeAssigneeDialogData} from "./change-assignee-dialog-layout.config";
import {SsabUserService} from "../../../service/user/ssab-user.service";
import {SsabNgbDateParserFormatter} from "../../shared/datepicker/ssab-datepicker-parser-formatter";
import {DateUtils} from "../../../shared/util/date-utils";
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'ssab-cx-change-assignee-dialog',
  templateUrl: './change-assignee-dialog.component.html',
})
export class ChangeAssigneeDialogComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  allowedAssignees$: Observable<UserGroup[]>
  currentAssignee: UserGroup;
  showTemporaryDates: boolean = false;
  subscriptions: Subscription = new Subscription();
  assigneesInput$ = new BehaviorSubject<string>(null);

  selectedType: ClaimAssigneeTypes;
  assigneeTypes = ClaimAssigneeTypes;
  claim: SsabClaim;

  constructor(protected fb: UntypedFormBuilder,
              protected launchDialogService: LaunchDialogService,
              protected globalMessageService: GlobalMessageService,
              protected translationService: TranslationService,
              protected cdr: ChangeDetectorRef,
              protected userService: SsabUserService,
              protected language: LanguageService,
              protected ngbDateParserFormatter: SsabNgbDateParserFormatter,
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.launchDialogService.data$.subscribe((dialogData: SsabChangeAssigneeDialogData) => {
        if (dialogData) {
          this.allowedAssignees$ = new Observable<UserGroup[]>((subscriber: Subscriber<UserGroup[]>) => subscriber.next(dialogData.allowedAssignees));
          this.currentAssignee = dialogData.currentAssignee;
          this.claim = dialogData.claim;
          this.changeType(this.claim.tempAssigneeFrom ? ClaimAssigneeTypes.Temporary : ClaimAssigneeTypes.Fixed);
          this.initForm();
          this.cdr.detectChanges();
        }
      })
    );
  }

  initForm() {
    this.form = this.fb.group(
      {
        assignee: [this.currentAssignee?.uid, [Validators.required]],
        type: [this.selectedType, [Validators.required]],
        assignmentDateStart: [this.claim.tempAssigneeFrom ? this.convertFormDate(this.claim.tempAssigneeFrom) : '', []],
        assignmentDateEnd: [this.claim.tempAssigneeTo ? this.convertFormDate(this.claim.tempAssigneeTo) : '', []],
        message: ['', []],
      }
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  clearAssignees(): void {
    this.assigneesInput$.next(undefined);
  }

  updateAssignee() {
    this.userService.updateClaimAssignee(
      this.claim.code,
      {
        temporary: this.form.value.type === ClaimAssigneeTypes.Temporary,
        uid: this.form.value.assignee,
        message: this.form.value.message,
        assignmentDateStart: this.convertNgbDateToOccString(this.form.value.assignmentDateStart),
        assignmentDateEnd: this.convertNgbDateToOccString(this.form.value.assignmentDateEnd)
      } as SsabClaimAssigneeRequest
    ).then(() => {
      this.globalMessageService.add(
        {
          key: 'ssab.claim.request.assignee',
        },
        GlobalMessageType.MSG_TYPE_CONFIRMATION
      );
      this.dismiss('updated');
    });
  }

  changeType(type: ClaimAssigneeTypes) {
    this.selectedType = type;
    this.showTemporaryDates = type === ClaimAssigneeTypes.Temporary;
  }

  assign() {
    this.updateAssignee();
  }

  convertFormDate(d: string): NgbDate {
    return this.ngbDateParserFormatter.parseDate(DateUtils.convertDate(d));
  }

  convertNgbDateToOccString(d: any): string {
    if (d) {
      if (d.year && d.month && d.day) {
        return DateUtils.convertDateToOccString(this.language, this.ngbDateParserFormatter.toDate(d));
      }
      return DateUtils.convertDateToOccString(this.language, d);
    }
    return null;
  }

  public dismiss(reason: any = 'closed'): void {
    this.launchDialogService.clear(LAUNCH_CALLER.CHANGE_ASSIGNEE);
    this.launchDialogService.closeDialog('closed');
  }
}
