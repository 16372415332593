<div class="modal show cart-notification" tabindex="-1" role="dialog" *ngIf="notificationType">
  <div class="modal-dialog" role="document" *ngIf="cart$ |async as cart" >
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close($event)">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="container">
          <h3 class="pb-2">
            <span class="ssab-icon icon-md {{icon}} icon-red icon-inline pr-4" *ngIf="icon"></span>
            {{(translationLabel + notificationType + '.title')  | cxTranslate}}
          </h3>
          <p [innerHTML]="(translationLabel + notificationType + '.content') | cxTranslate"></p>
          <p *ngIf="notificationType!='unpaidInvoices'" [innerHTML]="(translationLabel + notificationType + '.extraContent')  | cxTranslate : {amount: cart.exceededCreditAmount?.formattedValue} "></p>
        </div>
      </div>
    </div>
  </div>
</div>
