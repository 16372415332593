<!-- Template for days -->
<ng-template #customHeaderDay let-date let-currentMonth="currentMonth" let-today="today" let-disabled="disabled"
             let-selected="selected">
  <ssab-datepicker-day
    [today]="today"
    [disabled]="!isDateAllowed(date)"
    [selected]="selected"
    [hidden]="date.month !== currentMonth"
    [hasContent]="isDateAllowed(date)"
    [date]="date"
  >
  </ssab-datepicker-day>
</ng-template>

<div class="d-block d-sm-none pb-3 pl-3 pr-3" *ngIf="hasGlobalDeliveryDate">
  <label class="text-bold text-lg text-white">{{ 'ssab.cart.item.desiredDate' | cxTranslate }}</label>
  <ssab-datepicker-input [errorMessage]="'ssab.calendar.error'"
                         [nameForm]="'desiredDeliveryDate'"
                         [idName]="'desiredDeliveryDate_1'"
                         [typeInput]="'text'"
                         [parentForm]="cartForm"
                         [customDayTemplate]="customHeaderDay"
                         [selectedDate]="getDesiredDeliveryDate()"
                         (onChangeEvent)="setRequestedDate($event, true)"
                         [allowedDates]="allowedDates"
                         class="w-100 col-no-padding"


  >
  </ssab-datepicker-input>
</div>


<div class="cx-item-list">
  <div class="row item-header no-gutters">
    <div [ngClass]="hasGlobalDeliveryDate ? 'col-lg-6 col-md-4 col-12' : 'col-12'">
      <h3 class="m-0 align-self-center">
        {{ 'ssab.order.details' | cxTranslate }}
      </h3>
      <p class="mb-3 pt-4 text-paragraph-lg d-none d-md-block">{{ 'ssab.order.header.amount' | cxTranslate : {value: cart.entries.length} }}</p>
      <label class="pt-2 d-block d-md-none">{{ 'ssab.order.header.amount' | cxTranslate : {value: cart.entries.length} }}</label>
    </div>

    <ng-container *ngIf="hasGlobalDeliveryDate">
      <div class=" col-6 col-md-8 col-lg-6 row col-no-padding justify-content-end d-none d-sm-flex">
        <label class="col-form-label text-bold text-lg">{{ 'ssab.cart.item.desiredDate' | cxTranslate }}</label>
        <ssab-datepicker-input [errorMessage]="'ssab.calendar.error'"
                               [nameForm]="'desiredDeliveryDate'"
                               [idName]="'desiredDeliveryDate_2'"
                               [typeInput]="'text'"
                               [parentForm]="cartForm"
                               [customDayTemplate]="customHeaderDay"
                               [selectedDate]="getDesiredDeliveryDate()"
                               (onChangeEvent)="setRequestedDate($event, true)"
                               [allowedDates]="allowedDates"
                               class="pl-3 calendar-md col-no-padding">
        </ssab-datepicker-input>
      </div>
    </ng-container>
    <label class="m-0" class=" col-12 d-block d-lg-none">{{ 'common.swipe.instructions' | cxTranslate }}</label>
  </div>

  <!-- Template for days -->
  <ng-template #customDay let-date let-currentMonth="currentMonth" let-today="today" let-disabled="disabled"
               let-selected="selected">
    <ssab-datepicker-day
      [today]="today"
      [disabled]="false"
      [selected]="selected"
      [hidden]="date.month !== currentMonth"
      [hasContent]="true"
      [date]="date"
    >
    </ssab-datepicker-day>
  </ng-template>

  <div class="cx-item-list-table">
    <div *ngIf="hasHeader" class="pt-2">
      <div class="cx-item-list-header row text-bold col-no-margin">
        <div [ngClass]="'cx-item-list-desc row col-'+(showPricesOrConsignmentPricesStock ? cart.showIpiTax? '3': '4' : '6')">
          <div class="col-4">
          </div>
          <div class="col-8">
            {{ 'ssab.cart.item.description' | cxTranslate }}
          </div>
        </div>
        <div class="cx-item-list-qty col-2">
          <div class="row">
            <div class="col-7">
              {{ 'ssab.cart.item.quantity' | cxTranslate }}
            </div>
            <div class="col-5">
              <span class="pl-3">{{ 'ssab.cart.item.unit' | cxTranslate }}</span>
            </div>
          </div>
        </div>
        <div class="col-1">
          {{ 'ssab.cart.item.weight' | cxTranslate }}
        </div>
        <div class="col-3 row">
          <div class="col-6">
            {{ 'ssab.cart.item.deliveryDate' | cxTranslate }}
          </div>
          <div class="col-6">
            {{ 'ssab.cart.item.warehouse' | cxTranslate }}
          </div>
        </div>
        <ng-container *ngIf="showPricesOrConsignmentPricesStock">
          <div class="cx-item-list-price col-1">
            {{ 'ssab.cart.item.unitPrice' | cxTranslate }}
          </div>
          <div class="cx-item-list-price col-1">
            {{ 'ssab.cart.item.totalPrice' | cxTranslate }}
          </div>
        </ng-container>
        <div class="cx-item-list-price col-1" *ngIf="cart.showIpiTax">
          {{ 'ssab.cart.item.ipiTax' | cxTranslate }}
        </div>
      </div>
    </div>
    <div class="cx-item-list-row" *ngFor="let item of cart.entries; let i = index">
      <div
        class="cx-item-list-items"
        *ngIf="getControl(item) | async as control"
        [class.is-changed]="control.get('quantity').dirty"
      >
        <ssab-cx-cart-item-normal
          [cart]="cart"
          [item]="item"
          [quantityControl]="control.get('quantity')"
          [readonly]="readonly"
          [promotionLocation]="promotionLocation"
          [options]="options"
          [showPrices]="showPricesOrConsignmentPricesStock"
        ></ssab-cx-cart-item-normal>
      </div>
    </div>
  </div>


  <div class="cx-item-list-total order-totals">
    <div class="row justify-content-end">
      <div [ngClass]="showCartTotals ? 'col-md-8 col-12' : 'col-sm-8 col-12'">
        <div class="splitted-orders col-12" *ngIf="hasSplittedOrders || (cart && showCartTotals)">
          <ng-container *ngIf="hasSplittedOrders">
            <label>{{ 'ssab.cart.header.deliveryInformation.label'|cxTranslate }}</label>
            <p>{{ 'ssab.cart.header.deliveryInformation.text'|cxTranslate }}</p>
          </ng-container>
          <p *ngIf="cart && showCartTotals && cart.deliveryDatesIndicativeText"
             [innerHTML]="cart.deliveryDatesIndicativeText">
          </p>
        </div>
        <div class="pb-2 px-0 col-12" *ngIf="showCartTotals && cart">
          <p class="text-bold mb-1">{{ 'ssab.cart.header.totalWeight'|cxTranslate }}</p>
          <div>{{ cart.totalDisplayWeight }} <span [innerHTML]="cart.weightUnit?.name"></span></div>
        </div>
      </div>

      <div *ngIf="cart && !showCartTotals"
           [ngClass]="showCartTotals ? 'col-md-6 col-12 pl-md-0' : 'col-sm-8 col-12 pl-md-0'">
        <p *ngIf="cart.deliveryDatesIndicativeText" [innerHTML]="cart.deliveryDatesIndicativeText">
        </p>
        <p *ngIf="cart.deliveryFeeInfoText"
           class="text-paragraph-lg text-bold text-delayed" [innerHTML]="cart.deliveryFeeInfoText">
        </p>
      </div>
      <div class="col-sm-4 col-8 total-weight pt-sm-0 pt-3" *ngIf="cart && !showCartTotals">
        <div class="pb-2 row">
          <span class="col-6">{{ 'ssab.cart.header.totalWeight'|cxTranslate }}</span>
          <div class="col-6">{{ cart.totalDisplayWeight }} <span [innerHTML]="cart.weightUnit?.name"></span></div>
        </div>
        <div class="row" *ngIf="!showCartTotals && showPricesOrConsignmentPricesStock">
          <h6 class="text-bold col-6 total-sum">{{ 'ssab.cart.header.price.total'|cxTranslate }}</h6>
          <div class="text-bold col-6 total-sum text-paragraph-lg">{{ cart.subTotal?.formattedValue }}</div>
          <ng-container *ngIf="cart.deliveryCost">
            <h6 class="text-bold col-6">
              <ng-container
                *ngIf="cart.deliveryCostSurcharge; else regularDeliveryCost">{{ 'ssab.cart.header.price.deliverySurcharge'|cxTranslate }}
              </ng-container>
              <ng-template #regularDeliveryCost>
                {{ 'ssab.cart.header.price.deliveryCost'|cxTranslate }}
              </ng-template>
            </h6>
            <div class="text-bold col-6 text-paragraph-lg">{{ cart.deliveryCost?.formattedValue }}</div>
          </ng-container>
          <ng-container class="row" *ngIf="cart.showIpiTax">
            <h6 class="text-bold col-6">{{ 'ssab.cart.item.ipiTax'|cxTranslate }}</h6>
            <div class="text-bold col-6 text-paragraph-lg">{{ cart.totalTax?.formattedValue }}</div>
          </ng-container>
          <ng-container *ngIf=cart.bundleSplitTotal?.value>
            <h6 class="text-bold col-6">{{ 'ssab.cart.header.price.bundleSplitTotal'|cxTranslate }}</h6>
            <div class="text-bold col-6 text-paragraph-lg">{{ cart.bundleSplitTotal?.formattedValue }}</div>
          </ng-container>
        </div>
      </div>
      <div class="col-md-4 col-12" *ngIf="showCartTotals && showPricesOrConsignmentPricesStock">
        <ssab-cx-cart-totals
          [cart]="cart"
          [readonly]="true"
          [showInCartList]="true"
        ></ssab-cx-cart-totals>
      </div>
    </div>
  </div>

</div>

