import {DIALOG_TYPE, LayoutConfig} from '@spartacus/storefront';
import {ChangeAssigneeDialogComponent} from "./change-assignee-dialog.component";
import {UserGroup} from "@spartacus/organization/administration/core";
import {SsabClaim} from "../../../model/claim.mode";

declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    CHANGE_ASSIGNEE = 'CHANGE_ASSIGNEE',
  }
}

export const changeAssigneeLayout: LayoutConfig = {
  launch: {
    CHANGE_ASSIGNEE: {
      inlineRoot: true,
      component: ChangeAssigneeDialogComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};

export interface SsabChangeAssigneeDialogData {
  allowedAssignees?: UserGroup[];
  currentAssignee?: UserGroup;
  claim: SsabClaim;
}
