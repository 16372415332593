const cartFields = 'requestedDeliveryDate,requestedDeliveryDateEnteredManually,deliveryMode(code),paymentMode(code),unloadingMethod(code),warehousePhoneNumber,orderConfirmationEmail,b1OrderConfirmationEmail,deliveryNoteEmail,' +
  'materialCertificateEmail,billingAddress(id),deliveryAddress(FULL),purchaseOrderNumber,freeTextForTransportation,freeTextForWarehouse,freeTextForInvoice,orderConfirmationEmailEnteredManually,b1OrderConfirmationEmailEnteredManually,' +
  'deliveryNoteEmailEnteredManually,materialCertificateEmailEnteredManually,icmsPercentage,showIpiTax,taxRate,quotationMode,unpaidInvoices,showB1CreditMessageCart,showB1CreditMessageHeader';

export const ssabOccCartConfig = {
  backend: {
    occ: {
      endpoints: {
        carts: 'users/${userId}/carts?fields=carts(DEFAULT,' + cartFields + ',user,saveTime,name,description)',
        cart: 'users/${userId}/carts/${cartId}?fields=DEFAULT',
        addEntries: 'users/${userId}/carts/${cartId}/entries?fields=DEFAULT&spinner=true',
        updateEntries: 'users/${userId}/carts/${cartId}/entries/${entryNumber}?fields=entryNumber,unit(code),requestedDeliveryDate,doubleQuantity,splitBundle,product(code),splitBatch,splitSurchargeExists,batchItemNote,taxAmount&spinner=true',
        removeEntries: 'users/${userId}/carts/${cartId}/entries/${entryNumber}',
        updateHeader: 'users/${userId}/carts/${cartId}/header?fields=' + cartFields + '&spinner=true',
        placeOrder: 'users/${userId}/orders?fields=DEFAULT&spinner=true',
        multipleEntries: 'users/${userId}/carts/${cartId}/multipleEntries?fields=DEFAULT&spinner=true',
      }
    }
  }
};

