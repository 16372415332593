import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {GlobalMessageService, GlobalMessageType, LanguageService, UserIdService} from '@spartacus/core';
import {NgbDate, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {SsabNgbDateParserFormatter} from '../../../shared/datepicker/ssab-datepicker-parser-formatter';
import {SsabUserService} from '../../../../service/user/ssab-user.service';
import {SsabActiveCartService} from '../../../../service/cart/ssab-active-cart.service';
import {SsabCart, SsabOrderEntry} from '../../../../model/cart.model';
import {DateUtils} from '../../../../shared/util/date-utils';
import {CartItemListComponent} from '@spartacus/cart/base/components';
import {SelectiveCartService} from '@spartacus/cart/base/core';
import {SsabMultiCartService} from "../../../../service/cart/ssab-multi-cart.service";
import {Subscription} from "rxjs";

@Component({
  template: ''
})
export abstract class SsabCartItemListBaseComponent extends CartItemListComponent implements OnInit, OnDestroy {
  @Input() hasGlobalDeliveryDate = false;
  @Input() cart: SsabCart;
  @Input() showCartTotals = false;
  @Input() showPricesOrConsignmentPricesStock = true;
  @Input() orderConfirmation = false;
  allowedDates: {};
  hasSplittedOrders = false;
  hasItemOutOfStock = false;
  cartForm: UntypedFormGroup;
  requestedDeliveryDate: string;
  subscriptions: Subscription = new Subscription();

  constructor(protected fb: UntypedFormBuilder,
              protected ngbDateParserFormatter: SsabNgbDateParserFormatter,
              protected activeCartService: SsabActiveCartService,
              protected selectiveCartService: SelectiveCartService,
              protected userIdService: UserIdService,
              protected multiCartService: SsabMultiCartService,
              protected language: LanguageService,
              protected globalMessageService: GlobalMessageService,
              protected userService: SsabUserService,
              protected cdr: ChangeDetectorRef) {
    super(activeCartService, selectiveCartService, userIdService, multiCartService, cdr);
  }

  ngOnInit() {
    this.subscriptions.add(
      this.activeCartService.getActive().subscribe(cart => {
        this.requestedDeliveryDate = cart.requestedDeliveryDate;
        this.cartForm = this.fb.group(
          {
            desiredDeliveryDate: [this.getDesiredDeliveryDate()],
          });
        cart?.entries?.forEach((entry) => {
          if (entry.expectedSchedules?.length > 1) {
            this.hasSplittedOrders = true;
          }
          if (this.itemHasErrors(entry)) {
            this.hasItemOutOfStock = true;
            this.cdr.detectChanges();
          }
        });
        this.allowedDates = cart.allowedDeliveryDates?.reduce((map, date) => {
          map[date] = date;
          return map;
        }, {});
      })
    );
  }

  itemHasErrors(item: SsabOrderEntry): boolean {
    return (item.availabilityMessage
        && item.availabilityMessage.key
        && item.availabilityMessage.severity === 'ERROR')
      || item.product.stock?.stockLevelStatus === 'outOfStock';
  }

  setRequestedDate(date: Date, runErpSimulation: boolean): void {
    this.activeCartService.updateCartHeader({
        code: this.cart.code,
        requestedDeliveryDate: DateUtils.convertDateToOccString(this.language, date),
        requestedDeliveryDateEnteredManually: true
      } as SsabCart, runErpSimulation
    );
  }

  getDesiredDeliveryDate(): NgbDateStruct {
    return this.ngbDateParserFormatter.parseDate(this.requestedDeliveryDate ? DateUtils.convertDate(this.requestedDeliveryDate) : new Date());
  }

  isDateAllowed(date: NgbDate): boolean {
    return this.allowedDates && this.allowedDates[this.ngbDateParserFormatter.toString(date)] != null;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscriptions.unsubscribe();
  }
}
