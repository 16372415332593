<div [ngClass]="isPLPView? '': 'cx-price-pdp'">
  <div [ngClass]="product.stock?.viewOnly ? 'pb-5' : 'pb-4'" *ngIf="!isPLPView; else plpPrice">
    <div class="row">
      <ng-container *ngIf="showPricesOrConsignmentPricesStock$ | async">
        <div class="col-12 text-right" *ngIf="!product.nonSapProduct && product.price else noExistingPrice">
          <ng-container *ngTemplateOutlet="comparisonPrice"></ng-container>
        </div>
        <ng-template #noExistingPrice>
          <h3 *ngIf="this.product.stock?.consignment"
              class="col-12 text-right"> {{ 'ssab.product.stock.inStock.noPrice.consignment' |cxTranslate }}</h3>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="this.product.price?.scalePrice">
        <div class="text-delayed text-right col-7 offset-5" *ngIf="baseSite$ | async as baseSite">
          {{ 'ssab.product.price.scale' | cxTranslate }}
        </div>
      </ng-container>
    </div>
    <div class="row pt-2">
      <div class="col-12 text-right">
        <ng-container *ngTemplateOutlet="stock;context:{ css:'pt-1', isPDP : true}"></ng-container>
      </div>
    </div>
  </div>

  <ng-template #comparisonPrice>
    <ssab-cx-product-comparison-price
      [product]="product"
      [selectedUnit]="product.unit">
    </ssab-cx-product-comparison-price>
  </ng-template>


  <ng-template #plpPrice>
    <div class="row cx-price-plp">
      <div class="text-right col-12" *ngIf="product.showOfflineProductText">
        <h3>{{ 'ssab.product.offlineproduct' |cxTranslate }}</h3>
      </div>
      <ng-container *ngIf="(showPricesOrConsignmentPricesStock$ | async)">
        <div class="text-right col-12" aria-label="Product price"
             *ngIf="!product.nonSapProduct && product.price else noExistingPrice">
          <ng-container *ngTemplateOutlet="comparisonPrice"></ng-container>
        </div>
        <ng-template #noExistingPrice>
          <div class="text-right col-12" aria-label="Product price">
            <h3
              *ngIf="this.product.stock?.consignment"> {{ 'ssab.product.stock.inStock.noPrice.consignment' |cxTranslate }}</h3>
          </div>
        </ng-template>
        <ng-container *ngIf="this.product.price?.scalePrice">
          <div class="text-delayed text-right col-8 offset-4" *ngIf="baseSite$ | async as baseSite">
            {{ 'ssab.product.price.scale' | cxTranslate }}
          </div>
        </ng-container>
      </ng-container>
      <div class="col-12 cx-price-stock">
        <ng-container *ngTemplateOutlet="stock;context:{ css:'', isPDP : false}"></ng-container>
      </div>
    </div>
  </ng-template>

  <ng-template #stock let-css="css" let-isPDP="isPDP">
    <ng-container *ngIf="product.selectableStocks.length>1 ; else oneStock">
      <div [ngClass]="{'float-right': !isPDP, 'd-inline-block':isPDP}">
        <ng-container *ngTemplateOutlet="oneStock"></ng-container>
      </div>
    </ng-container>
    <ng-template #oneStock>
      <ng-container *ngFor="let stock of product.selectableStocks; let last = last">
        <div class="{{css}}">
          <ng-container>
            <div class="{{css}}">
              <ng-container *ngTemplateOutlet="stockLevels;context:{stock:stock,printName:true}"></ng-container>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-template>
  </ng-template>


  <ng-template #stockLevels let-stock="stock" let-printName="printName">
    <ng-container *ngIf="product.selectableStocks.length>1 ; else normalStock">
      <div class="d-lg-flex align-items-center form-check mb-0" [formGroup]="warehouseForm">
        <input
          id="radio_{{product.code}}_{{stock.warehouseCode}}"
          *ngIf="product.selectableStocks.length>1 && stock.stockInfoDisplay?.showRadioButton"
          class="form-check-input mt-0"
          type="radio"
          [ngModel]="this.product.stock.warehouseCode"
          value="{{stock.warehouseCode}}"
          formControlName="warehouseCode"
          (change)="changeStock(stock)"
        >
        <div class="d-inline-block mr-2" *ngIf="printName">{{ stock.name }}&nbsp;</div>
        <div class="d-inline-block text-bold text-lg-left "
             [ngClass]="!stock.stockInfoDisplay?.status ? '': 'cx-stock-level-'+ stock.stockInfoDisplay?.status">
          <i class="ssab-icon icon-inline icon-dot mr-2 pb-1" *ngIf="stock.stockInfoDisplay?.showTrafficLight">&nbsp;</i>
          <div [ngClass]="{'text-capitalize-first d-inline-block ': stock.stockInfoDisplay?.status}"
               [innerHTML]="stock.stockInfoDisplay?.label">
          </div>
        </div>

        <div class="text-right text-bold text-capitalize-first" *ngIf="stock.stockInfoDisplay?.extraLabel">
          {{ stock.stockInfoDisplay?.extraLabel }}
        </div>
      </div>
    </ng-container>

    <ng-template #normalStock>
      <div class="row">
        <div class="col-12 text-right">
          <span class="stock-line-single mr-2" *ngIf="printName">{{ stock.name }}&nbsp;</span>
          <span class="text-bold text-capitalize-first stock-line-single" *ngIf="stock.stockInfoDisplay?.extraLabel">
            {{ stock.stockInfoDisplay?.extraLabel }}
          </span>
          <span [ngClass]="!stock.stockInfoDisplay?.status ? '': 'cx-stock-level-'+ stock.stockInfoDisplay?.status">
            <i class="ssab-icon icon-inline icon-dot mr-2" *ngIf="stock.stockInfoDisplay?.showTrafficLight">&nbsp;</i>
            <span class="text-bold stock-line-single" [ngClass]="{'text-capitalize-first d-inline-block ': stock.stockInfoDisplay?.status}"
                  [innerHTML]="stock.stockInfoDisplay?.label">
            </span>
          </span>
        </div>
      </div>
    </ng-template>
  </ng-template>

  <ssab-cx-add-to-cart
    [showQuantity]="true"
    [selectedUnit]="product.unit.code"
    [product]="product"
    [minQuantity]="product.stock.minimumAvailableQuantity"
    [stockLevel]="product.stock.stockLevel"
    [currentStock]="product.stock"
    (update)="onChange($event)"
    [isPLPView]="isPLPView"
    [isSplitBundle]="product.bundleSplitActivatedByDefault"
  ></ssab-cx-add-to-cart>
</div>

