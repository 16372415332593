import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {OccEndpointsService} from '@spartacus/core';
import {OCRResult, SsabCertificateDocumentList, SsabDocumentIdentifier, SsabDocumentIdentifierList, SsabDocumentSearchRequest, SsabInvoiceDocumentList, SsabOrderConfirmationDocumentList, SsabRelatedDocumentList, SsabTransportDocumentList} from '../../model/document.model';
import {map, catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SsabOccUserDocumentAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) {
  }

  searchByMaterialId(userId: string, materialId: string): Observable<SsabCertificateDocumentList> {
    const url = this.occEndpoints.buildUrl('searchByMaterialId', {urlParams: {userId, materialId}});
    return this.http.get<SsabCertificateDocumentList>(url);
  }

  searchByOcr(userId: string, data: Blob): Observable<OCRResult> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream'
    });
    const url = this.occEndpoints.buildUrl('searchByOcr', {urlParams: {userId}});
    return this.http.post<OCRResult>(url, data, {headers});
  }

  sendDocumentsAsEmail(userId: string, params: SsabDocumentIdentifierList, email: string): Observable<void> {
    const url = this.occEndpoints.buildUrl('sendDocumentsAsEmail', {urlParams: {userId}, queryParams: {email}});
    return this.http.post<void>(url, params);
  }

  public getRelatedDocuments(userId: string, orderId: string, customerId: string, searchRelevantIdsFromOrders: boolean): Observable<SsabRelatedDocumentList> {
    const url = this.occEndpoints.buildUrl('relatedDocuments', {urlParams: {userId, orderId, customerId}, queryParams: {searchRelevantIdsFromOrders}});
    return this.http.get<SsabRelatedDocumentList>(url);
  }

  public loadCertificateDocuments(userId: string, params: SsabDocumentSearchRequest, trimMaterialIds?: string): Observable<SsabCertificateDocumentList> {
    const url = this.occEndpoints.buildUrl('getCertificateDocuments', {urlParams: {userId}, queryParams: {trimMaterialIds}});
    return this.http.patch<SsabCertificateDocumentList>(url, params);
  }

  public loadOrderConfirmationDocuments(userId: string, params: SsabDocumentSearchRequest): Observable<SsabOrderConfirmationDocumentList> {
    const url = this.occEndpoints.buildUrl('getOrderConfirmationDocuments', {urlParams: {userId}});
    return this.http
      .patch<SsabOrderConfirmationDocumentList>(url, params)
      .pipe(map(documents => {
          return documents;
        }
      ));
  }

  public loadInvoiceDocuments(userId: string, params: SsabDocumentSearchRequest): Observable<SsabInvoiceDocumentList> {
    const url = this.occEndpoints.buildUrl('getInvoiceDocuments', {urlParams: {userId}});
    return this.http.patch<SsabInvoiceDocumentList>(url, params);
  }

  public loadTransportDocuments(userId: string, params: SsabDocumentSearchRequest): Observable<SsabTransportDocumentList> {
    const url = this.occEndpoints.buildUrl('getTransportDocuments', {urlParams: {userId}});
    return this.http.patch<SsabTransportDocumentList>(url, params);
  }

  public downloadDocument(userId: string, params: SsabDocumentIdentifier, showHeaders?: boolean): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Accept', 'application/pdf');
    const url = this.occEndpoints.buildUrl('downloadDocument', {urlParams: {userId}});
    if (showHeaders) {
      return this.http.patch<SsabTransportDocumentList>(url, params, {headers, responseType: 'arraybuffer' as 'json', observe: 'response'});
    } else {
      return this.http.patch<SsabTransportDocumentList>(url, params, {headers, responseType: 'blob' as 'json'});
    }

  }

  public downloadDocuments(userId: string, params: SsabDocumentIdentifierList): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Accept', 'application/zip');
    const url = this.occEndpoints.buildUrl('downloadDocuments', {urlParams: {userId}});
    return this.http.patch<SsabTransportDocumentList>(url, params, {headers, responseType: 'blob' as 'json'});
  }

  public downloadAllDocuments(userId: string, params: SsabDocumentSearchRequest): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Accept', 'application/zip');
    const url = this.occEndpoints.buildUrl('downloadAllDocuments', {urlParams: {userId}});
    return this.http.patch<any>(url, params, {headers, responseType: 'blob' as 'json'});
  }

  public sendELOQUAData(url: string, params?: HttpParams): Observable<any> {
    return this.http.post(url, null, {params, responseType: 'text'}).pipe(
      catchError((error) => {
        console.error(error);
        return of({});
      })
    )

  }
}
