import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {SsabProductService} from "../../../service/product/ssab-product.service";
import {GlobalMessageService, LanguageService} from "@spartacus/core";
import {SsabClaim} from "../../../model/claim.mode";
import {SsabUserService} from "../../../service/user/ssab-user.service";
import {SsabGlobalconfigurationService} from "../../../service/general/configuration/ssab-globalconfiguration.service";
import {Observable, of} from "rxjs";
import {DateUtils} from '../../../shared/util/date-utils';

@Component({
  selector: 'ssab-cx-claim-messages',
  templateUrl: './ssab-claim-messages.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClaimMessagesComponent implements OnDestroy, OnInit, AfterViewChecked {
  @Input()
  claim: SsabClaim;

  @ViewChild('messagesWrapper', { static: false }) messagesWrapper: ElementRef;

  updatedClaim$: Observable<SsabClaim>;

  form: UntypedFormGroup;

  constructor(private globalConfigurationService: SsabGlobalconfigurationService,
              protected productService: SsabProductService,
              protected router: Router,
              protected fb: UntypedFormBuilder,
              protected cdr: ChangeDetectorRef,
              protected actions$: Actions,
              protected languageService: LanguageService,
              protected userService: SsabUserService,
              protected globalMessageService: GlobalMessageService,
  ) {
    this.form = this.fb.group({
      message: ['']
    });
  }

  ngOnInit(): void {
    this.updatedClaim$ = of(this.claim);
  }

  ngAfterViewChecked(): void {
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    if (this.messagesWrapper) {
      const wrapperElement = this.messagesWrapper.nativeElement;
      wrapperElement.scrollTop = wrapperElement.scrollHeight;
    }
  }

  ngOnDestroy(): void {

  }

  onSubmit(): void {
    if (this.form.valid) {
      const message = this.form.get('message')?.value;
      this.userService.sendMessage(this.claim.code, message).subscribe(response => {
        this.form.reset();
        this.updatedClaim$ = of(response);
        this.cdr.detectChanges();
      });
    }
  }

  convertUIDate(d: string): Date {
    return DateUtils.convertDate(d);
  }

  getDateTimeFormat(): Observable<string> {
    return this.globalConfigurationService.getDateTimeFormat();
  }
}
