<div class="cart-header" *ngIf="cart$ | async as cart" [formGroup]="headerForm"
     [ngClass]="{'mt-0': (isConsignmentCart$ | async)}">

  <div *ngIf="cart.totalItems > 0">
    <ng-container *ngIf="!(isConsignmentCart$ | async); else consignmentHeader">
      <ng-container *ngIf="configuration$| async as conf">
        <div class="header-fields" [ngClass]="{'stretched-contact-details' : conf.checkout.stretchContactInformation}">
          <!-- payment -->

          <div class="payment-details header-block"
               [ngClass]="{'stretched-contact-details' : conf.checkout.stretchContactInformation}">
            <div>
              <div class="field-header">
                {{ 'ssab.cart.header.payment'|cxTranslate }}
              </div>
              <ng-container *ngFor="let paymentMode of cart.allowedPaymentModes">
                <div class="form-check mb-0" *ngIf="paymentMode.code">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="{{paymentMode.code}}"
                    formControlName="paymentMode"
                    (change)="postBack(cart.code, $event, true)"
                  >
                  <h7 class="form-check-label pb-3">
                    {{ 'ssab.cart.header.' + paymentMode.code | cxTranslate | uppercase }}
                    <ng-container *ngIf=" !paymentMode.prePayment">
                      &nbsp; ({{ cart.paymentTerms }})
                    </ng-container>
                  </h7>
                </div>
              </ng-container>
            </div>

            <div *ngIf="conf.checkout.showFreeTextForInvoice" class="form-group pt-3 conf-test">
              <label class="text-opacity">{{ 'ssab.cart.header.freeTextForInvoice'|cxTranslate }}</label>
              <ssab-cx-input
                idName="freeTextForInvoice" [isNotValidFunction]="isInvalid('freeTextForInvoice')"
                [nameForm]="'freeTextForInvoice'"
                [labelPlaceholder]="'common.write'"
                (change)="postBack(cart.code, $event, false)"
                [parentForm]="headerForm"></ssab-cx-input>
            </div>
          </div>

          <!-- contact -->
          <div class="contact-details header-block"
               [ngClass]="{'stretched-contact-details' : conf.checkout.stretchContactInformation}">
            <div class="field-header">
              {{ 'ssab.cart.header.contact'|cxTranslate }}
            </div>

            <div class="field-content">
              <label>{{ 'ssab.cart.header.buyer'|cxTranslate }}</label>
              <div class="field-value">
                {{ cart.buyerAddress?.companyName + " - " + cart.buyerAddress?.formattedAddress }}
              </div>
            </div>

            <div class="field-content" *ngIf="cart.payerAddress">
              <label>{{ 'ssab.cart.header.payer'|cxTranslate }}</label>
              <div class="field-value">
                {{ cart.payerAddress?.companyName + " - " + cart.payerAddress?.formattedAddress }}
              </div>
            </div>

            <div class="field-content" *ngIf="cart.allowedBillingAddresses?.length>0">
              <label>{{ 'ssab.cart.header.billingAddress'|cxTranslate }}</label>
              <div class="field-value" *ngIf="!conf.checkout.readonlyInvoicingAddress; else readonlyInvoicingAddress">
                <ng-select
                  (change)="postBack(cart.code, $event, true)"
                  [searchable]="false"
                  [clearable]="false"
                  [closeOnSelect]="true"
                  dropdownPosition="bottom"
                  bindLabel="formattedAddress"
                  bindValue="id"
                  formControlName="billingAddress"
                  class="w-80vw"
                  [items]="cart.allowedBillingAddresses"
                ></ng-select>
              </div>
              <ng-template #readonlyInvoicingAddress>
                {{ cart.allowedBillingAddresses[0]?.companyName + " - " + cart.allowedBillingAddresses[0]?.formattedAddress }}
              </ng-template>
            </div>

            <div class="field-content">
              <label>{{ 'ssab.cart.header.orderConfirmationEmail'|cxTranslate }}</label>


              <div *ngIf="cart.b1OrderConfirmationReceivers?.length > 0; else orderConfirmationEmailAddress">
                <ssab-cx-add-email
                  [receivers]="cart.b1OrderConfirmationReceivers"
                  [isAdditionalEmailVisible]="cart.b1OrderConfirmationEmailEnteredManually"
                  [emailAddressLabel]="'ssab.cart.header.addAdditionalEmailAddress.label'"
                  [emailAddressPlaceholder]="'ssab.cart.header.addAdditionalEmailAddress.placeholder'"
                  [parentForm]="headerForm"
                  [emailName]="'b1OrderConfirmationEmail'"
                  [emailManuallyName]="'b1OrderConfirmationEmailEnteredManually'"
                  (postBack)="postBackEmail(cart.code,$event, emailTypes.b1OrderConfirmation)"
                  (resetEmail)="resetAdditionalEmail(cart.code,emailTypes.b1OrderConfirmation)">
                </ssab-cx-add-email>
              </div>
              <ng-template #orderConfirmationEmailAddress>
                <div *ngIf="cart.orderConfirmationReceivers?.length > 0; else manuallyProvidedOrderConfirmationEmailAddress">
                  <ssab-cx-add-email
                    [receivers]="cart.orderConfirmationReceivers"
                    [isAdditionalEmailVisible]="cart.orderConfirmationEmailEnteredManually"
                    [emailAddressLabel]="'ssab.cart.header.addAdditionalEmailAddress.label'"
                    [emailAddressPlaceholder]="'ssab.cart.header.addAdditionalEmailAddress.placeholder'"
                    [parentForm]="headerForm"
                    [emailName]="'orderConfirmationEmail'"
                    [emailManuallyName]="'orderConfirmationEmailEnteredManually'"
                    (postBack)="postBackEmail(cart.code,$event, emailTypes.orderConfirmation)"
                    (resetEmail)="resetAdditionalEmail(cart.code,emailTypes.orderConfirmation)">
                  </ssab-cx-add-email>
                </div>
              </ng-template>
              <ng-template #manuallyProvidedOrderConfirmationEmailAddress>
                <div class="field-value">
                  <ssab-cx-input
                    idName="orderConfirmationEmail"
                    [isNotValidFunction]="isInvalid('orderConfirmationEmail')"
                    [errorMessage]="'common.invalid.email'"
                    [nameForm]="'orderConfirmationEmail'"
                    (afterChange)="postBack(cart.code, $event, false)"
                    [parentForm]="headerForm"></ssab-cx-input>
                </div>
              </ng-template>
            </div>

            <div class="field-content" *ngIf="conf.checkout.showDeliveryNoteEmailSection">
              <label>{{ 'ssab.cart.header.deliveryNoteEmail'|cxTranslate }}</label>
              <div *ngIf="cart.deliveryNoteReceivers?.length > 0; else manuallyProvidedDeliveryNoteEmailAddress">
                <ssab-cx-add-email
                  [receivers]="cart.deliveryNoteReceivers"
                  [isAdditionalEmailVisible]="cart.deliveryNoteEmailEnteredManually"
                  [emailAddressLabel]="'ssab.cart.header.addAdditionalEmailAddress.label'"
                  [emailAddressPlaceholder]="'ssab.cart.header.addAdditionalEmailAddress.placeholder'"
                  [parentForm]="headerForm"
                  [emailName]="'deliveryNoteEmail'"
                  [emailManuallyName]="'deliveryNoteEmailEnteredManually'"
                  (postBack)="postBackEmail(cart.code,$event, emailTypes.deliveryNote)"
                  (resetEmail)="resetAdditionalEmail(cart.code,emailTypes.deliveryNote)">
                </ssab-cx-add-email>
              </div>

              <ng-template #manuallyProvidedDeliveryNoteEmailAddress>
                <div class="field-value">
                  <ssab-cx-input
                    idName="deliveryNoteEmail"
                    [isNotValidFunction]="isInvalid('deliveryNoteEmail')"
                    [errorMessage]="'common.invalid.email'"
                    [nameForm]="'deliveryNoteEmail'"
                    (afterChange)="postBack(cart.code, $event, false)"
                    [parentForm]="headerForm"></ssab-cx-input>
                </div>
              </ng-template>
            </div>

            <div class="field-content" *ngIf="conf.checkout.showMaterialCertificateEmail">
              <label>{{ 'ssab.cart.header.materialCertificateEmail'|cxTranslate }}</label>
              <div *ngIf="cart.materialCertificateReceivers?.length > 0; else manuallyProvidedMaterialCertificateEmailAddress">
                <ssab-cx-add-email
                  [receivers]="cart.materialCertificateReceivers"
                  [isAdditionalEmailVisible]="cart.materialCertificateEmailEnteredManually"
                  [emailAddressLabel]="'ssab.cart.header.addAdditionalEmailAddress.label'"
                  [emailAddressPlaceholder]="'ssab.cart.header.addAdditionalEmailAddress.placeholder'"
                  [parentForm]="headerForm"
                  [emailName]="'materialCertificateEmail'"
                  [emailManuallyName]="'materialCertificateEmailEnteredManually'"
                  (postBack)="postBackEmail(cart.code,$event, emailTypes.materialCertificate)"
                  (resetEmail)="resetAdditionalEmail(cart.code,emailTypes.materialCertificate)">
                </ssab-cx-add-email>
              </div>

              <ng-template #manuallyProvidedMaterialCertificateEmailAddress>
                <div class="field-value">
                  <ssab-cx-input
                    idName="materialCertificateEmail"
                    [isNotValidFunction]="isInvalid('materialCertificateEmail')"
                    [errorMessage]="'common.invalid.email'"
                    [nameForm]="'materialCertificateEmail'"
                    (afterChange)="postBack(cart.code, $event, false)"
                    [parentForm]="headerForm"></ssab-cx-input>
                </div>
              </ng-template>
            </div>

            <div class="field-content">
              <label>{{ 'ssab.cart.header.purchaseOrderNumber.label'|cxTranslate }}</label>
              <div class="field-value">
                <ssab-cx-input
                  idName="purchaseOrderNumber"
                  [isNotValidFunction]="isInvalid('purchaseOrderNumber')"
                  [nameForm]="'purchaseOrderNumber'"
                  (afterChange)="postBack(cart.code, $event, false)"
                  [parentForm]="headerForm"
                  [maxlength]="'35'"
                  [labelPlaceholder]="'ssab.cart.header.purchaseOrderNumber.placeholder'"></ssab-cx-input>
              </div>
            </div>
          </div>
          <!-- delivery -->
          <div class="header-block delivery-details"
               [ngClass]="{'stretched-contact-details' :conf.checkout.stretchContactInformation}">
            <div class="field-header">
              {{ 'ssab.cart.header.delivery.label'|cxTranslate }}
            </div>
            <ng-container *ngIf="conf.checkout.showDeliveryMode">
              <ng-container *ngFor="let msg of cart.infoMessages">
                <label *ngIf="msg.key.startsWith('ssab.cart.header.deliveryMode')">{{ msg.key|cxTranslate }}</label>
              </ng-container>
              <div class="field-content">
                <label>{{ 'ssab.cart.header.deliveryMode.title'|cxTranslate }}</label>
                <div class="field-value">
                  <ng-select
                    (change)="postBack(cart.code, $event, true)"
                    [searchable]="false"
                    [clearable]="false"
                    [closeOnSelect]="true"
                    dropdownPosition="bottom"
                    bindLabel="name"
                    bindValue="code"
                    formControlName="deliveryMode"
                    class="w-80vw"
                    [items]="cart.allowedDeliveryModes"
                    placeholder="{{ 'common.selectOption' | cxTranslate }}"
                  ></ng-select>
                </div>
              </div>
            </ng-container>

            <div class="field-content" *ngIf="!cart.deliveryMode?.pickup">
              <label>{{ 'ssab.cart.header.deliveryAddress'|cxTranslate }}</label>
              <div class="field-value">
                <ng-select
                  (change)="postBack(cart.code, $event, true)"
                  [searchable]="false"
                  [clearable]="false"
                  [closeOnSelect]="true"
                  dropdownPosition="bottom"
                  bindLabel="formattedAddress"
                  bindValue="id"
                  formControlName="deliveryAddress"
                  class="w-80vw"
                  [items]="cart.allowedDeliveryAddresses"
                  placeholder="{{ 'common.selectOption' | cxTranslate }}"
                ></ng-select>
              </div>
              <div *ngIf="conf.checkout.showAddNewAddressForDelivery" class="add-new-address field-value pt-2">
                <a (click)="addNewAddress(cart.code)">
                  {{ 'ssab.cart.header.addNewAddress'|cxTranslate }}
                </a>
              </div>
            </div>

            <div class="field-content">
              <label>{{ 'ssab.cart.header.incoTerm.title'|cxTranslate }}</label>
              <div class="field-value">
                <span *ngIf="cart.incoTerm1">{{ cart.incoTerm1 }} </span>
                <span *ngIf="cart.incoTerm2">{{ cart.incoTerm2 }}</span>
                <span>,&nbsp;{{ 'ssab.cart.header.incoTerm.text'|cxTranslate }}</span>
              </div>
            </div>

            <ng-container *ngIf="cart.deliveryMode?.pickup">
              <div class="field-content">
                <label>{{ 'ssab.cart.header.warehouseLocations'|cxTranslate }}</label>
                <ng-container *ngFor="let wh of cart.warehouseLocations">
                  <div class="field-content warehouseAddress">
                  <span *ngIf="wh.companyName"
                        [ngClass]="{'text-bold': !conf.checkout.showPdfTransportationPickup}">
                    {{ wh.companyName }}
                  </span>
                    <div class="field-value">
                      {{ wh.formattedAddress }}
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>

            <ng-container *ngIf="!cart.deliveryMode?.pickup">
              <!--Hide thsi for the moment for task #9439.Checkout: Hide/Remove the "Receiving Location Opening Hours"-section-->
              <div class="field-content" *ngIf="false">
                <label>{{ 'ssab.cart.header.warehouseOpeningHours'|cxTranslate }}</label>
                <div class="field-value" [innerHTML]="cart.warehouseOpeningHours">
                </div>
              </div>
              <div *ngIf="conf.checkout.showWarehousePhoneNumber" class="field-content">
                <label>{{ 'ssab.cart.header.warehousePhoneNumber'|cxTranslate }}</label>
                <div class="field-value">
                  <ssab-cx-input
                    idName="warehousePhoneNumber" [isNotValidFunction]="isInvalid('warehousePhoneNumber')"
                    [nameForm]="'warehousePhoneNumber'"
                    (afterChange)="postBack(cart.code, $event, false)"
                    [parentForm]="headerForm"></ssab-cx-input>
                </div>
              </div>
            </ng-container>

            <div class="field-content position-relative" *ngIf="conf.checkout.showFreeTextForWarehouse">
              <label class="d-inline-block">{{ 'ssab.cart.header.freeTextForWarehouse.label'|cxTranslate }}</label>
              <span class="ssab-icon icon-system-info ml-2 icon-inline icon-cursor" role="button" placement="right"
                    popoverClass="popover-xs popover-info" [ngbPopover]="popContentFreeTextForWarehouse"
                    triggers="manual" (click)="popover.open()" #popover="ngbPopover"></span>
              <div class="field-value">
                <ssab-cx-input
                  idName="freeTextForWarehouse"
                  [isNotValidFunction]="isInvalid('freeTextForWarehouse')"
                  [nameForm]="'freeTextForWarehouse'"
                  (afterChange)="postBack(cart.code, $event, false)"
                  [parentForm]="headerForm"
                  [isTextArea]="true"
                  [labelPlaceholder]="'common.write'"
                ></ssab-cx-input>
              </div>
            </div>

            <ng-container *ngIf="!cart.deliveryMode?.pickup">
              <div *ngIf="conf.checkout.showUnloadingMethod" class="field-content">
                <label>{{ 'ssab.cart.header.unloadingMethod'|cxTranslate }}</label>
                <div class="field-value">
                  <ng-select
                    (change)="postBack(cart.code, $event, true)"
                    [searchable]="false"
                    [clearable]="false"
                    [closeOnSelect]="true"
                    dropdownPosition="bottom"
                    bindLabel="name"
                    bindValue="code"
                    formControlName="unloadingMethod"
                    class="w-80vw"
                    [items]="cart.allowedUnloadingMethods"
                    placeholder="{{ 'common.selectOption' | cxTranslate }}"
                  ></ng-select>
                </div>
              </div>
            </ng-container>

            <div *ngIf="cart.showFreeTextForTransportation"
                 class="field-content">
              <label class="d-inline-block">{{ 'ssab.cart.header.freeTextForTransportation.label'|cxTranslate }}</label>
              <span class="ssab-icon icon-system-info ml-2 icon-inline icon-cursor" role="button" placement="right"
                    popoverClass="popover-sm popover-info" [ngbPopover]="popContentFreeTextForTransportation"
                    triggers="manual" (click)="popover.open()" #popover="ngbPopover"></span>
              <div class="field-value">
                <ssab-cx-input
                  idName="freeTextForTransportation" [isNotValidFunction]="isInvalid('freeTextForTransportation')"
                  [nameForm]="'freeTextForTransportation'"
                  (afterChange)="postBack(cart.code, $event, false)"
                  [parentForm]="headerForm"
                  [isTextArea]="true"
                  [labelPlaceholder]="'common.write'"
                ></ssab-cx-input>
              </div>
            </div>

            <ng-container *ngIf="cart.deliveryMode?.pickup ">
              <div class="field-content" *ngIf="!conf.checkout.showPdfTransportationPickup">
                <label></label>
                <div class="field-value" [innerHTML]="'ssab.cart.header.pickupInfo1'|cxTranslate">
                </div>
              </div>
              <div class="field-content" *ngIf="user$|async as user">
                <label></label>
                <div class="field-value" *ngIf="!conf.checkout.showPdfTransportationPickup"
                     [innerHTML]="'ssab.cart.header.pickupInfo2'|cxTranslate:{SalesContactsEmail: emails(user)}">
                </div>
              </div>
              <div *ngIf="conf.checkout.showPdfTransportationPickup">
                <a class="text-primary text-underline-blue text-bold"
                   target="_blank" [href]="openPdfTransportationPickup() | async">{{ 'ssab.cart.header.pdf.pickup'|cxTranslate }} </a>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #consignmentHeader>
      <div class="header-total order-totals mb-4" *ngIf="showPricesOrConsignmentPricesStock$ | async as showPricesOrConsignmentPricesStock">
        <div class="row justify-content-end">
          <div class="col-lg-4 col-md-5 col-12">
            <label class="text-opacity">{{ 'ssab.cart.header.purchaseOrderNumber.label'|cxTranslate }}</label>
            <div class="field-value">
              <ssab-cx-input
                idName="purchaseOrderNumber"
                [isNotValidFunction]="isInvalid('purchaseOrderNumber')"
                [nameForm]="'purchaseOrderNumber'"
                (afterChange)="postBack(cart.code, $event, false)"
                [parentForm]="headerForm"
                [maxlength]="'35'"
                [labelPlaceholder]="'ssab.cart.header.purchaseOrderNumber.placeholder'"></ssab-cx-input>
            </div>
            <div class="field-content m-0 pt-4">
              <label>{{ 'ssab.cart.header.orderConfirmationEmail'|cxTranslate }}</label>
              <div *ngIf="cart.orderConfirmationReceivers?.length > 0; else manuallyProvidedOrderConfirmationEmailAddress">
                <ssab-cx-add-email
                  [receivers]="cart.orderConfirmationReceivers"
                  [isAdditionalEmailVisible]="cart.orderConfirmationEmailEnteredManually"
                  [showAdditionalEmail]="true"
                  [emailAddressLabel]="'ssab.cart.header.addAdditionalEmailAddress.label'"
                  [emailAddressPlaceholder]="'ssab.cart.header.addAdditionalEmailAddress.placeholder'"
                  [parentForm]="headerForm"
                  [emailName]="'orderConfirmationEmail'"
                  [emailManuallyName]="'orderConfirmationEmailEnteredManually'"
                  (postBack)="postBackEmail(cart.code,$event, emailTypes.orderConfirmation)"
                  (resetEmail)="resetAdditionalEmail(cart.code,emailTypes.orderConfirmation)">
                </ssab-cx-add-email>
              </div>

              <ng-template #manuallyProvidedOrderConfirmationEmailAddress>
                <div class="field-value">
                  <ssab-cx-input
                    idName="orderConfirmationEmail"
                    [isNotValidFunction]="isInvalid('orderConfirmationEmail')"
                    [errorMessage]="'common.invalid.email'"
                    [nameForm]="'orderConfirmationEmail'"
                    (afterChange)="postBack(cart.code, $event, false)"
                    [parentForm]="headerForm"></ssab-cx-input>
                </div>
              </ng-template>
            </div>
          </div>
          <div class="col-lg-3 col-sm-4 col-8 total-weight pt-sm-0 pt-3 ml-sm-auto mr-3 mr-sm-0"
               *ngIf="cart">
            <div class="pb-2 row">
              <span class="col-6">{{ 'ssab.cart.header.totalWeight'|cxTranslate }}</span>
              <div class="col-6">{{ cart.totalDisplayWeight }} <span [innerHTML]="cart.weightUnit?.name"></span></div>
            </div>
            <div class="row" *ngIf="showPricesOrConsignmentPricesStock">
              <h6 class="text-bold col-6 total-sum">{{ 'ssab.cart.header.price.total'|cxTranslate }}</h6>
              <div class="text-bold col-6 total-sum text-paragraph-lg">{{ cart.subTotal?.formattedValue }}</div>
            </div>
            <div class="row" *ngIf="!cart.consignment && cart.deliveryCost">
              <h6 class="text-bold col-6">
                <ng-container
                  *ngIf="cart.deliveryCostSurcharge; else regularDeliveryCost">{{ 'ssab.cart.header.price.deliverySurcharge'|cxTranslate }}
                </ng-container>
                <ng-template #regularDeliveryCost>
                  {{ 'ssab.cart.header.price.deliveryCost'|cxTranslate }}
                </ng-template>
              </h6>
              <div class="text-bold  col-6 text-paragraph-lg">{{ cart.deliveryCost?.formattedValue }}</div>
            </div>
            <div class="row" *ngIf="cart.bundleSplitTotal?.value">
              <h6 class="text-bold col-6">{{ 'ssab.cart.header.price.bundleSplitTotal'|cxTranslate }}</h6>
              <div class="text-bold  col-6 text-paragraph-lg">{{ cart.bundleSplitTotal?.formattedValue }}</div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <!-- summary -->
  <div *ngIf="cart.totalItems > 0">
    <ng-container *ngIf="showPricesOrConsignmentPricesStock$ | async">
      <ssab-cx-cart-totals [cart]="cart"></ssab-cx-cart-totals>
    </ng-container>

    <div class="place-order">
      <div class="text-right form-check" *ngIf="!(isConsignmentCart$ | async)">
        <input #termsAndCoditions
               class="form-check-input position-relative"
               id="termsAndCoditions"
               type="checkbox" aria-label="..."
               (change)="termsAndConditionsChange(termsAndCoditions.checked)">
        <label class="form-check-label pl-3 text-lg" [innerHtml]="'ssab.cart.checkout.acceptTerms' | cxTranslate"
               for="termsAndCoditions"></label>
      </div>

      <button *ngIf="user$|async as user"
              (click)="clear(cart.code, user.uid )"
              type="button"
              class="btn btn-block btn-default-height remove-cart-button btn-tertiary">
        {{ 'ssab.cart.header.removeCart' | cxTranslate }}
      </button>
      <button
        [disabled]="!cart.validForOrdering || submitted || !termsAndConditionsAccepted"
        (click)="placeOrder()"
        type="button"
        class="btn btn-info btn-block btn-default-height place-order-button">
        {{ (cart.quotationMode ? 'ssab.cart.header.placeQuotation' : 'ssab.cart.header.placeOrder') | cxTranslate }}
      </button>
    </div>
  </div>
  <!--Popover templates  -->
  <ng-template #popContentFreeTextForWarehouse>
    <span class="ssab-icon icon-system-info icon-inline"></span>
    <span class="pl-2">{{ 'ssab.cart.notification.popover.freeTextForWarehouse' | cxTranslate }}</span>
    <span class="ssab-icon icon-navigation-close icon-md d-block d-sm-none"></span>
  </ng-template>

  <ng-template #popContentFreeTextForTransportation>
    <span class="ssab-icon icon-system-info icon-inline"></span>
    <span class=" pl-2">{{ 'ssab.cart.notification.popover.freeTextForTransportation' | cxTranslate }}</span>
    <span class="ssab-icon icon-navigation-close icon-md d-block d-sm-none"></span>
  </ng-template>
</div>



