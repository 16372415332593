import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output, QueryList, ViewChild,
  ViewChildren
} from '@angular/core';
import {DateUtils} from "../../../shared/util/date-utils";
import {Observable} from 'rxjs';
import {SsabGlobalconfigurationService} from "../../../service/general/configuration/ssab-globalconfiguration.service";
import {TablesTypes} from "../../../model/misc.model";
import {ClaimDetailTypes, SsabClaim} from "../../../model/claim.mode";
import {ICON_TYPE, LAUNCH_CALLER, LaunchDialogService} from '@spartacus/storefront';
import {SsabChangeAssigneeDialogData} from '../change-assignee-dialog/change-assignee-dialog-layout.config';
import {SsabChangeStatusDialogData} from "../change-status-dialog/change-status-dialog-layout.config";
import {SsabDocumentIdentifier, SsabRelatedDocumentList} from "../../../model/document.model";
import {UserGroup} from "@spartacus/organization/administration/core";
import {SsabUserService} from "../../../service/user/ssab-user.service";
import {SsabUser} from "../../../model/user.model";
import {HttpParams} from "@angular/common/http";
import {SsabTableHeadComponent} from "../../shared/table-head/ssab-table-head.component";

@Component({
  selector: 'ssab-cx-claim-list-item',
  templateUrl: './claim-list-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClaimListItemComponent implements OnDestroy, OnInit {
  @Input()
  claim: SsabClaim;
  @Input()
  allowedAssignees: UserGroup[];
  user$: Observable<SsabUser>;
  @Input()
  allowedStatuses: string[];
  @ViewChildren('selectedDocument') selectedDocuments!: QueryList<ElementRef<HTMLInputElement>>;
  @ViewChild('selectAllDocuments') selectAllDocumentsElement!: ElementRef<HTMLInputElement>;
  @Output() doSearch = new EventEmitter<void>();

  documents$: Observable<SsabRelatedDocumentList>;

  detailsOpen = false;
  selectedDetail: string = ClaimDetailTypes.Details;
  detailTypes = ClaimDetailTypes;
  tableHeadType = TablesTypes.ClaimsDocuments;
  selectAllDocuments: boolean;
  iconTypes = ICON_TYPE;
  documentsSelectedToDownload: SsabDocumentIdentifier[] = [];

  constructor(private globalConfigurationService: SsabGlobalconfigurationService,
              protected launchDialogService: LaunchDialogService,
              protected cdr: ChangeDetectorRef,
              protected userService: SsabUserService,) {
    this.user$ = this.userService.getUserDetails();
  }

  ngOnInit(): void {
    this.documents$ = this.userService.getRelatedClaimDocuments(this.claim.code, this.claim.orderNumber, this.claim.customer?.uid);
  }

  ngOnDestroy(): void {
  }

  toggleDetails(): void {
    this.detailsOpen = !this.detailsOpen;
  }

  changeDetailType(documentType: string): void {
    this.selectedDetail = documentType;

    if (documentType === this.detailTypes.Messages && this.claim.unreadMessages > 0) {
      this.setMessagesRead();
      this.claim.unreadMessages = 0;
    }
  }

  openMessages(documentType: string): void {
    this.toggleDetails();
    this.changeDetailType(documentType);
  }

  checkAllDocuments(isChecked: boolean): void {
    this.selectAllDocuments = isChecked;
    if (isChecked) {
      this.triggerChangeSelected(true);
    } else {
      this.triggerChangeSelected(false);
    }
  }

  onChange(isChecked: boolean, document: SsabDocumentIdentifier): void {
    if (isChecked) {
      if (!this.documentsSelectedToDownload.some(el => el.documentId === document.documentId)) {
        this.documentsSelectedToDownload.push(document);
      }
    } else {
      if (this.selectAllDocumentsElement) {
        this.selectAllDocumentsElement.nativeElement.checked = false;
      }
      this.documentsSelectedToDownload = this.documentsSelectedToDownload
        .filter(function (doc) {
          return document.documentId !== doc.documentId;
        });
    }
  }

  triggerChangeSelected(isChecked: boolean) {
    this.selectedDocuments.forEach((checkbox, index) => {
      checkbox.nativeElement.checked = isChecked;
      const event = new Event('change', { bubbles: true });
      checkbox.nativeElement.dispatchEvent(event);
    });
  }

  setMessagesRead(): void {
    this.userService.setClaimMessagesRead(this.claim.code);
  }

  convertUIDate(d: string): Date {
    return DateUtils.convertDate(d);
  }

  getDateFormat(): Observable<string> {
    return this.globalConfigurationService.getDateFormat();
  }

  openChangeAssigneeModal(): void {
    this.launchDialogService.closeDialog(null);
    this.launchDialogService.openDialog(
      LAUNCH_CALLER.CHANGE_ASSIGNEE,
      undefined,
      undefined,
      {
        allowedAssignees: this.claim.possibleAssignees,
        currentAssignee: this.claim.assignee,
        claim: this.claim
      } as SsabChangeAssigneeDialogData)
      .subscribe(
        () => {
          this.doSearch.emit();
          this.cdr.detectChanges();
        }
      );
  }

  openChangeStatusModal(): void {
    this.launchDialogService.closeDialog(null);
    this.launchDialogService.openDialog(
      LAUNCH_CALLER.CHANGE_STATUS,
      undefined,
      undefined,
      {
        currentStatus: this.claim.claimStatus,
        claim: this.claim
      } as SsabChangeStatusDialogData)
      .subscribe(
        () => {
          this.doSearch.emit();
          this.cdr.detectChanges();
        }
      );
  }

  downloadDocumentsSelected(): void {
    this.documentsSelectedToDownload.forEach((document) => {
      this.downloadSingleDocument(document);
    })
  }

  downloadSingleDocument(documentToDownload: SsabDocumentIdentifier): void {
    if (documentToDownload.fileMime) {
      this.userService.downloadSingleAttachment(documentToDownload);
    } else {
      this.userService.downloadClaimDocument(documentToDownload);
    }
  }
}
