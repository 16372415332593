import {Component, OnInit} from '@angular/core';
import {PageLayoutService, ProductListComponent, ViewConfig, ViewModes} from '@spartacus/storefront';
import {SsabProductListComponentService} from '../../../../service/product/ssab-product-list-component.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, take, withLatestFrom,} from 'rxjs/operators';
import {SsabGtmService} from '../../../../service/analytics/gtm/ssab-gtm.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {SsabUserService} from '../../../../service/user/ssab-user.service';
import {GlobalMessageService} from '@spartacus/core';
import {SsabActiveCartService} from "../../../../service/cart/ssab-active-cart.service";
import {SsabDeliveryMode} from "../../../../model/cart.model";

@Component({
  selector: 'ssab-cx-product-list',
  templateUrl: './ssab-product-list.component.html',
})
export class SsabProductListComponent extends ProductListComponent implements OnInit {

  isInternalCustomer$: Observable<boolean>;
  previousPageSize: number;
  viewMode$ = new BehaviorSubject<ViewModes>(ViewModes.List);
  noResultsMessage$: Observable<string>;
  productPageDeliverMode$: Observable<SsabDeliveryMode>;

  constructor(
    private aPageLayoutService: PageLayoutService,
    private aProductListComponentService: SsabProductListComponentService,
    public ssabGtmService: SsabGtmService,
    public scrollConfig: ViewConfig,
    public messagesService: GlobalMessageService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected userService: SsabUserService,
    protected viewConfig: ViewConfig,
    protected activeCartService: SsabActiveCartService
  ) {
    super(aPageLayoutService, aProductListComponentService, messagesService, scrollConfig);
    this.previousPageSize = this.viewConfig.view.defaultPageSize;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.isInternalCustomer$ = this.userService.isShowCustomerSelection();
    this.aPageLayoutService.templateName$
      .pipe(take(1))
      .subscribe((template) => {
        this.viewMode$.next(ViewModes.List);
      });
    this.noResultsMessage$ =
      this.userService.isShowCustomerSelection()
        .pipe(withLatestFrom(this.userService.hasStockLocations()), map(([internalCustomer, hasStockLocation]) => {
          if (internalCustomer) {
            return "productList.internal.customer.noResult";
          } else if (!hasStockLocation) {
            return "productList.noStockLocation.noResult";
          } else {
            return "productList.noResult";
          }
        }));
  }


  changeListingProducts(pageSize: number, pageNumber: number): void {
    this.viewConfig.view.defaultPageSize = pageSize;
    this.previousPageSize = pageSize;

    // Fix, change current page so that it does not get stuck in previous value
    const queryParams: Params = {currentPage: pageNumber};
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams,
        queryParamsHandling: 'merge'
      });
  }

  stockAvaliabilityDownload(totalResults: number, pageNumber: number, sort: string): void {
    this.aProductListComponentService.downloadStockAvailability(totalResults, pageNumber, sort);
  }
}
