<div class="cx-claims-messages" *ngIf="updatedClaim$ | async as ssabClaim">
  <div class="cx-claims-messages-wrapper" #messagesWrapper>
    <ng-container *ngFor="let message of ssabClaim.claimMessages">
      <div class="cx-claims-messages-item" [ngClass]="{'own': message.current}">
        <div class="row">
          <div class="col-8 text-bold">
            {{ message.messageOwner }}
          </div>
          <div class="col-4 text-gray text-right">
            {{ convertUIDate(message.messageDate)|cxDate: (getDateTimeFormat()|async) }}
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            {{ message.message }}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="cx-claims-messages-form">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="field-content">
        <h5>{{ 'ssab.claims.messages.message.label'|cxTranslate }}:</h5>
        <div class="field-value">
          <div class="d-flex">
            <div class="mr-3 flex-grow-1">
              <ssab-cx-input
                [typeInput]="'text'"
                [idName]="'message'"
                [nameForm]="'message'"
                [labelPlaceholder]="'ssab.claims.messages.message.placeholder'"
                [parentForm]="form">
              </ssab-cx-input>
            </div>
            <button class="btn btn-primary text-nowrap" type="submit">
              <i class="ssab-icon icon-social-mail icon-inline mr-2"></i>
              {{ 'ssab.claims.messages.send' | cxTranslate }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
