import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import {IconModule} from '@spartacus/storefront';
import {SsabAlertComponent} from './ssab-alert.component';
import {SsabPipesModule} from '../utils/safe-html/ssab-pipes.module';
import {NgbPopover} from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    imports: [CommonModule, IconModule, I18nModule, SsabPipesModule, NgbPopover],
  declarations: [SsabAlertComponent],
  exports: [SsabAlertComponent],
})
export class SsabAlertModule{}
