import {Inject, Injectable} from '@angular/core';
import {
  AuthActions,
  AuthRedirectService,
  AuthService,
  AuthStorageService,
  BaseSiteService,
  CurrencyService,
  GlobalMessageService,
  GlobalMessageType,
  LanguageService,
  OAuthLibWrapperService,
  RoutingService,
  StateWithClientAuth,
  UserIdService,
  WindowRef
} from '@spartacus/core';
import {Store} from '@ngrx/store';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {distinctUntilChanged, filter, map, take} from 'rxjs/operators';
import {AuthenticationResult} from '@azure/msal-browser';
import {Router} from '@angular/router';
import {CdcAuthentication} from '../../model/user.model';
import {openCloseSpinner} from '../../cms-components/shared/utils/functions/ssab-functions-utils';
import {GlobalLogin, SsabBaseSite} from '../../model/misc.model';
import {SsabAuthRedirectService} from './ssab-auth-redirect.service';
import {DOCUMENT} from "@angular/common";

export const AZURE_LOGIN_IN_PROGRESS_LOCAL_STORAGE_KEY = 'ssab.azure.login.in.progress';
export const CDC_LOGIN_IN_PROGRESS_LOCAL_STORAGE_KEY = 'ssab.cdc.login.in.progress';
export const AZURE_LOGIN_USERID = '__AZURE_AD__';
export const AZURE_LOGIN_USERID_ASM = '__AZURE_AD_ASM__';
export const CDC_TOKEN_USER = '___cdc_token___';
export const CDC_TOKEN_SEPARATOR = '___token___';

declare const gigya: any;

@Injectable({
  providedIn: 'root'
})
export class SsabAuthService extends AuthService {
  private azureLoginInProcess = new BehaviorSubject(false);

  constructor(
    protected aStore: Store<StateWithClientAuth>,
    protected aUserIdService: UserIdService,
    protected aOAuthLibWrapperService: OAuthLibWrapperService,
    protected aAuthStorageService: AuthStorageService,
    protected aAuthRedirectService: AuthRedirectService,
    protected aRoutingService: RoutingService,
    protected baseSiteService: BaseSiteService,
    protected router: Router,
    @Inject(DOCUMENT) private document: Document,
    protected ssabAuthRedirectService: SsabAuthRedirectService,
    protected languageService: LanguageService,
    protected globalMessageService: GlobalMessageService,
    protected currencyService: CurrencyService,
  ) {
    super(aStore, aUserIdService, aOAuthLibWrapperService, aAuthStorageService, aAuthRedirectService, aRoutingService);
  }

  isUserLoggedIn(): Observable<boolean> {
    return this.authStorageService.getToken().pipe(
      map((userToken) => {
        return Boolean(userToken.access_token);
      }),
      distinctUntilChanged()
    );
  }

  asmRedirectUponEmulation(): void {
    this.aAuthRedirectService.redirect();
  }

  public commerceLoginForAzureAuthentication(authentication: AuthenticationResult): void {
    this.azureLoginInProcess.next(true);
    this.loginWithCredentials(AZURE_LOGIN_USERID, authentication.accessToken)
      .then(() => {
        //this is required to handle logout. TODO should be refactored, since loginWithCredentials already should call this
        this.navigateToHomePageOrLoginPage();
        this.azureLoginInProcess.next(false);
      })
      .catch(err => {
        console.error(err);
        this.azureLoginInProcess.next(false);
      });
  }

  isAzureLoginInProcess(): BehaviorSubject<boolean> {
    return this.azureLoginInProcess;
  }

  public commerceLoginForCdcAuthentication(authentication: CdcAuthentication): void {
    const userToken = CDC_TOKEN_USER + authentication.uid;
    const authToken = encodeURIComponent(authentication.uidSignature) + CDC_TOKEN_SEPARATOR + authentication.signatureTimestamp;

    this.loginWithCredentials(userToken, authToken)
      .then(() => {
        openCloseSpinner(this.document, false);
        // no need for duplicate call
        //   this.navigateToHomePageOrLoginPage();
      })
      .catch(err => {
        openCloseSpinner(this.document, false);
        console.error(err);
      });
  }

  public isSSOLoginActive(): Observable<boolean> {
    if (this.document.location.href.includes('asm=true')) {
      return of(false);
    }
    return this.baseSiteService.get().pipe(
      map((baseSiteData: SsabBaseSite) => baseSiteData.ssoLogin)
    );
  }

  public setAzureLoginInProgress(inProgress: boolean): void {
    this.setExternalLoginInProgress(inProgress, AZURE_LOGIN_IN_PROGRESS_LOCAL_STORAGE_KEY);
  }

  public isAzureLoginInProgress(): boolean {
    return this.isExternalLoginInProgress(AZURE_LOGIN_IN_PROGRESS_LOCAL_STORAGE_KEY);
  }

  public setCdcLoginInProgress(inProgress: boolean): void {
    this.setExternalLoginInProgress(inProgress, CDC_LOGIN_IN_PROGRESS_LOCAL_STORAGE_KEY);
  }

  public isCdcLoginInProgress(): boolean {
    return this.isExternalLoginInProgress(CDC_LOGIN_IN_PROGRESS_LOCAL_STORAGE_KEY);
  }

  protected setExternalLoginInProgress(inProgress: boolean, key: string): void {
    if (inProgress) {
      this.getLocalStorage()?.setItem(key, 'true');
    } else {
      this.getLocalStorage()?.removeItem(key);
    }
  }

  protected isExternalLoginInProgress(key: string): boolean {
    const localStorage = this.getLocalStorage();
    return localStorage?.getItem(key) === 'true';
  }

  public navigateToHomePageOrLoginPage(): void {
    this.isUserLoggedIn()
      .pipe(take(1))
      .subscribe((isLoggedIn) => {
        this.setAzureLoginInProgress(false);
        this.setCdcLoginInProgress(false);
        if (isLoggedIn) {
          this.ssabAuthRedirectService.redirect(true);
        } else {
          // Force same url navigation
          this.router.navigated = false;
          this.router.navigate(['/login']);
        }
      });
  }

  protected getLocalStorage(): Storage {
    return this.document.defaultView?.localStorage;
  }

  protected getSessionStorage(): Storage {
    return this.document.defaultView?.sessionStorage;
  }

  refreshToken(): void {
    this.authStorageService.getToken().subscribe((token) => {
      const now = new Date();
      if (token.expires_at && token.access_token_stored_at) {
        const expirationTime = Math.abs(+token.expires_at - +token.access_token_stored_at);
        token.access_token_stored_at = (now.getTime()).toString();
        token.expires_at = (now.getTime() + expirationTime).toString();
        this.authStorageService.setToken(token);
      }
    }).unsubscribe();
  }

  coreLogout(): Promise<void> {
    this.userIdService.clearUserId();
    return new Promise((resolve) => {
      this.oAuthLibWrapperService.revokeAndLogout()
        .finally(() => {
          this.doLogout(resolve);
        });
    });
  }

  private doLogout(resolve: (value: (PromiseLike<void> | void)) => void): void {

    this.store.dispatch(new AuthActions.Logout());

    this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_CONFIRMATION, 0);
    // remove all active carts when logout
    Object.keys(this.getLocalStorage())
      .filter(key => key.startsWith('spartacus') && key.endsWith('cart'))
      .forEach(key => this.getLocalStorage()
        .removeItem((key))
      );
    // remove session active, used for session expired functionality
    this.getSessionStorage()?.removeItem(GlobalLogin.SessionActiveTime);
    this.currencyService.setActive('EUR');
    this.ssabAuthRedirectService.handleStoreSelection(GlobalLogin.GlobalBaseSite, false, false);

    this.isSSOLoginActive()
      .pipe(
        filter((active) => active),
        take(1)
      )
      .subscribe(() => {
        this.ssoLogout().then(() => this.finalizeLogout(resolve));
      });

    this.isSSOLoginActive()
      .pipe(
        filter((active) => !active),
        take(1)
      )
      .subscribe(() => {
          this.finalizeLogout(resolve);
        }
      );
  }

  finalizeLogout(resolve: (value: (PromiseLike<void> | void)) => void): void {
    // set language as last step, default to en
    this.languageService.setActive('en');
    this.currencyService.setActive('EUR');
    resolve();
    setTimeout(() => {
      openCloseSpinner(this.document, false);
      this.routingService.go('/login');
      // no reloading required
      // this.winRef.location.reload();
    }, 1000);
  }

  ssoLogin(language: string): void {
    if (gigya.getLoginToken() === null || gigya.getLoginToken() === undefined) {
      gigya.sso.login({
        authFlow: "redirect",
        // redirectURL: url,
        context: {
          appName: "eCom",
          lang: language
        },
        useChildContext: true
      });
    }
  }

  ssoLogout(): Promise<void> {
    console.log("SSO logging out")
    return new Promise((resolve) => {
      gigya.accounts.logout({
        callback: () => {
          console.log("SSO logged out")
          resolve();
        }
      });
    });
  }
}
