import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SsabGtmService} from '../../../../service/analytics/gtm/ssab-gtm.service';
import {TranslationService} from '@spartacus/core';
import {Observable, Subscription} from 'rxjs';
import {SsabCart} from '../../../../model/cart.model';
import {SsabActiveCartService} from '../../../../service/cart/ssab-active-cart.service';

@Component({
  selector: 'ssab-cx-cart-notification',
  templateUrl: './ssab-cart-notification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabCartNotificationComponent implements OnInit, OnDestroy {

  @Input() icon = 'icon-system-error';
  notificationType: string;
  @Input() translationLabel = 'ssab.cart.notification.';
  cart$: Observable<SsabCart>;
  subscriptions: Subscription = new Subscription();

  constructor(protected ssabGtmService: SsabGtmService,
              protected activeCartService: SsabActiveCartService,
              protected translationService: TranslationService,
              protected cdr: ChangeDetectorRef) {
    this.cart$ = this.activeCartService.getActive();
  }

  close($event: MouseEvent): void {
    const modal = (<HTMLElement>$event.target).closest('.modal');
    if (modal.classList.contains('show')) {
      modal.classList.remove('show');
    }
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.cart$.subscribe(cart => {
        this.notificationType = null;
        if (cart.showB1CreditMessageCart) {
          this.notificationType = 'creditLimitExceededB1';
        } else if (cart.creditLimitExceeded) {
          this.notificationType = 'creditLimitExceeded';
        }
        if (cart.creditPrepaymentRequired) {
          this.notificationType = 'creditPrepaymentRequired';
        }
        if (cart.unpaidInvoices) {
          this.notificationType = 'unpaidInvoices';
        }

        this.cdr.detectChanges();
      })
    );

    if (this.notificationType) {
      this.subscriptions.add(
        this.translationService.translate(this.translationLabel + this.notificationType + '.content')
          .subscribe(translated => this.ssabGtmService.pushErrorNotification('checkout notification', translated.replace(/(<([^>]+)>)/ig, '')))
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
