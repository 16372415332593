<!--Popover templates  -->
<ng-template #popContentOrderIDInfo>
  <div class="text-default row vertical-align-item">
    <span class="ssab-icon icon-system-info icon-inline col-2"></span>
    <span class="pl-0 text-opacity col-10">{{ 'ssab.claims.search.infoSearch' | cxTranslate }}</span>
  </div>
</ng-template>

<!-- Template for days -->
<ng-template #customDay let-date let-currentMonth="currentMonth" let-today="today" let-disabled="disabled"
             let-selected="selected">
  <ssab-datepicker-day
    [today]="today"
    [selected]="selected"
    [hidden]="date.month !== currentMonth"
    [hasContent]="true"
    [date]="date"
  >
  </ssab-datepicker-day>
</ng-template>

<div class="cx-claims-search">
  <div id="cx-claims-header">
    <h2 class="text-white cx-page-header-title">
      {{ 'ssab.claims.list.page.title' | cxTranslate }}
    </h2>
    <!-- Tabs -->
    <div class="cx-claims-tab row no-gutters w-100">
      <div class=" col-12 row no-gutters">
        <div
          class="col-auto col-md-4 col-lg-4 d-flex text-right order-3 order-md-2 text-bold col-no-padding ml-auto d-lg-none d-md-block">
          <span (click)="showFilterOptions($event)">
            {{ 'ssab.order.history.filter.true' | cxTranslate }}
            <i class="ssab-icon icon-system-filter icon-inline ml-3 icon-cursor icon-white"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="user$ | async as user">
    <ng-container *ngIf="user.internalUser">
      <!-- SEARCH CRITERIA -->
      <form [formGroup]="searchForm" (ngSubmit)="search()" *ngIf="claims$ | async as claims"
            class="cx-claims-form d-none d-lg-block">
        <div #claimSearch class="cx-search-form row">
          <h3 class="col-12 pb-5 d-lg-none d-md-block">{{ 'ssab.search.criteria' | cxTranslate }}
            <i class="ssab-icon icon-navigation-close icon-cursor float-right" (click)="hideFilterOptions($event)"></i>
          </h3>

          <div class="form-group cx-claims-search-option col-lg-3 col-12">
            <label class="text-lg">
              <span>{{ 'ssab.claims.search.reference.label' | cxTranslate }}</span>
              <span class="ssab-icon icon-inline icon-system-info d-none d-sm-inline-block ml-2 icon-white"
                    role="button"
                    placement="right"
                    popoverClass="popover-sm popover-info" [ngbPopover]="popContentOrderIDInfo"
                    triggers="manual" (click)="popover.open()" #popover="ngbPopover"></span>
            </label>
            <ssab-cx-input class="pr-0 input-no-border"
                           [typeInput]="'text'"
                           [idName]="'orderNumber'"
                           [nameForm]="'orderNumber'"
                           [parentForm]="searchForm"
                           [labelPlaceholder]="'ssab.claims.search.reference.placeholder'">

            </ssab-cx-input>
          </div>

          <div #divStatus class="form-group cx-claims-search-option cx-claims-search-select col-lg-3 col-12">
            <h4 class="option-header">
              <i class="ssab-icon icon-navigation-chevron-left icon-inline pr-3"
                 (click)="hideSearchOptions(divStatus)"></i>
              {{ 'ssab.claims.search.status.label' | cxTranslate }}
              <i class="float-right ssab-icon icon-navigation-close icon-inline"
                 (click)="hideSearchOptions(divStatus)"></i>
            </h4>
            <label class="text-lg">{{ 'ssab.claims.search.status.label' | cxTranslate }}
              <span class="ssab-icon icon-inline"></span>
            </label>
            <ng-select #status
                       [items]="claims?.allowedStatuses"
                       [searchable]="true"
                       [multiple]="true"
                       [closeOnSelect]="false"
                       [clearable]="true"
                       (click)="toggleMultiSelectFilter(divStatus, status)"
                       (change)="hideMultiSearchOptions(divStatus)"
                       dropdownPosition="bottom"
                       id="status"
                       formControlName="status"
                       placeholder="{{ 'ssab.order.history.status.selectAll' | cxTranslate }}">
              <ng-template ng-option-tmp let-item="item">
                <div class="form-check mb-0 d-inline">
                  <input class="form-check-input position-relative" type="checkbox">
                </div>
                <span>{{ "ssab.claims.status." + item |cxTranslate }}</span>
              </ng-template>
              <ng-template ng-label-tmp let-item="item">
                <span>{{ "ssab.claims.status." + item |cxTranslate }}</span>
              </ng-template>
              <ng-template ng-footer-tmp>
                <label class="form-check w-100 mb-1 d-inline-flex align-items-center multi-checkbox-all"
                       for="multiCheckboxForStatuses"
                       (mouseenter)="removeMultiSelectHighlight()">
                  <input type="checkbox" class="form-check-input select-all-items-checkbox"
                         id="multiCheckboxForStatuses" (change)="resetMultiSelect(status, $event)"/>
                  <p class="form-check-label ml-0 mb-0">
                    {{ 'ssab.order.history.status.selectAll' | cxTranslate }}
                  </p>
                </label>
              </ng-template>
            </ng-select>
          </div>

          <div #divAssignee class="form-group cx-claims-search-option cx-claims-search-select col-lg-3 col-12">
            <h4 class="option-header">
              <i class="ssab-icon icon-navigation-chevron-left icon-inline pr-3"
                 (click)="hideSearchOptions(divAssignee)"></i>
              {{ 'ssab.claims.search.assignee.label' | cxTranslate }}
              <i class="float-right ssab-icon icon-navigation-close icon-inline"
                 (click)="hideSearchOptions(divAssignee)"></i>
            </h4>
            <label class="text-lg">{{ 'ssab.claims.search.assignee.label' | cxTranslate }}
              <span class="ssab-icon icon-inline"></span>
            </label>
            <ng-select #assignee
                       [items]="claims?.allowedAssignees"
                       [searchable]="true"
                       [closeOnSelect]="true"
                       [clearable]="true"
                       (click)="toggleFilter(divAssignee)"
                       (change)="hideSearchOptions(divAssignee)"
                       [typeahead]="assigneesInput$"
                       (clear)="clearAssignees()"
                       dropdownPosition="bottom"
                       bindLabel="name"
                       bindValue="uid"
                       id="assignee"
                       formControlName="assignee"
                       placeholder="{{ 'ssab.order.history.status.selectAll' | cxTranslate }}">
              <ng-template ng-option-tmp let-item="item">
                <span title="{{item.name}}">{{ item.name }}</span>
              </ng-template>
              <ng-template ng-footer-tmp>
                <p class="w-100 mb-0"
                   (click)="resetSelect(assignee)">{{ 'ssab.order.history.status.selectAll' | cxTranslate }}</p>
              </ng-template>
            </ng-select>
          </div>

          <div #divCreationDateStart class="form-group cx-claims-search-option col-lg-3 col-12">
            <label class="text-lg d-none d-lg-block">{{ 'ssab.claims.search.dateFrom' | cxTranslate }}</label>
            <ssab-datepicker-input class="calendar-dark"
                                   [nameForm]="'creationDateStart'"
                                   [idName]="'creationDateStart'"
                                   [typeInput]="'text'"
                                   [parentForm]="searchForm"
                                   [isNotValidFunction]="isInvalid('creationDateStart')"
                                   [customDayTemplate]="customDay"
                                   [labelPlaceholder]="'ssab.claims.search.dateFrom'"
                                   [selectedDate]="convertDate(searchForm.get('creationDateStart').value)"
                                   (click)="toggleFilter(divCreationDateStart)"
                                   (onChangeEvent)="hideSearchOptions(divCreationDateStart)"
                                   [navigation]="calendarNavigationTypes.Select"
            >
            </ssab-datepicker-input>
          </div>

          <div #divCountries
               class="form-group col-lg-3 col-12 cx-claims-search-option cx-option-hide cx-claims-search-select"
               [ngClass]="{'d-lg-none': showHideFilter}">
            <h4 class="option-header">
              <i class="ssab-icon icon-navigation-chevron-left icon-inline pr-3"
                 (click)="hideSearchOptions(divCountries)"></i>
              {{ 'ssab.claims.search.country.label' | cxTranslate }}
              <i class="float-right ssab-icon icon-navigation-close icon-inline"
                 (click)="hideSearchOptions(divCountries)"></i>
            </h4>
            <label class="text-lg">{{ 'ssab.claims.search.country.label' | cxTranslate }}
              <span class="ssab-icon icon-inline"></span>
            </label>
            <ng-select #country
                       [items]="claims?.allowedCountries"
                       [searchable]="true"
                       [closeOnSelect]="true"
                       [clearable]="true"
                       (click)="toggleFilter(divCountries)"
                       (change)="hideSearchOptions(divCountries)"
                       [typeahead]="countriesInput$"
                       dropdownPosition="bottom"
                       bindLabel="name"
                       bindValue="isocode"
                       id="country"
                       formControlName="country"
                       (clear)="clearCountries()"
                       placeholder="{{ 'ssab.order.history.status.selectAll' | cxTranslate }}"
            >
              <ng-template ng-option-tmp let-item="item">
                <span title="{{item.name}}">{{ item.name }}</span>
              </ng-template>
              <ng-template ng-footer-tmp>
                <p class="w-100 mb-0"
                   (click)="resetSelect(country)">{{ 'ssab.order.history.status.selectAll' | cxTranslate }}</p>
              </ng-template>
            </ng-select>
          </div>

          <div #divDeliveryChannel
               class="form-group col-lg-3 col-12 cx-claims-search-option cx-option-hide cx-claims-search-select"
               [ngClass]="{'d-lg-none': showHideFilter}">
            <h4 class="option-header">
              <i class="ssab-icon icon-navigation-chevron-left icon-inline pr-3"
                 (click)="hideSearchOptions(divDeliveryChannel)"></i>
              {{ 'ssab.claims.search.deliveryChannel.label' | cxTranslate }}
              <i class="float-right ssab-icon icon-navigation-close icon-inline"
                 (click)="hideSearchOptions(divDeliveryChannel)"></i>
            </h4>
            <label class="text-lg">{{ 'ssab.claims.search.deliveryChannel.label' | cxTranslate }}
              <span class="ssab-icon icon-inline"></span>
            </label>
            <ng-select #deliveryChannel
                       [items]="claims?.allowedDeliveryChannels"
                       [searchable]="true"
                       [closeOnSelect]="true"
                       [clearable]="true"
                       (click)="toggleFilter(divDeliveryChannel)"
                       (change)="hideSearchOptions(divDeliveryChannel)"
                       [typeahead]="deliveryChannelsInput$"
                       dropdownPosition="bottom"
                       id="deliveryChannel"
                       formControlName="deliveryChannel"
                       (clear)="clearDeliveryChannels()"
                       placeholder="{{ 'ssab.order.history.status.selectAll' | cxTranslate }}"
            >
              <ng-template ng-option-tmp let-item="item">
                <span>{{ ("ssab.claim.channel." + (item | lowercase)) | cxTranslate }}</span>
              </ng-template>

              <ng-template ng-label-tmp let-item="item">
                <span>{{ ("ssab.claim.channel." + (item | lowercase)) | cxTranslate }}</span>
              </ng-template>
              <ng-template ng-footer-tmp>
                <p class="w-100 mb-0"
                   (click)="resetSelect(deliveryChannel)">{{ 'ssab.order.history.status.selectAll' | cxTranslate }}</p>
              </ng-template>
            </ng-select>
          </div>

          <div #divCustomers
               class="form-group col-lg-3 col-12 cx-claims-search-option cx-option-hide cx-claims-search-select"
               [ngClass]="{'d-lg-none': showHideFilter}">
            <h4 class="option-header">
              <i class="ssab-icon icon-navigation-chevron-left icon-inline pr-3"
                 (click)="hideSearchOptions(divCustomers)"></i>
              {{ 'ssab.claims.search.customer.label' | cxTranslate }}
              <i class="float-right ssab-icon icon-navigation-close icon-inline"
                 (click)="hideSearchOptions(divCustomers)"></i>
            </h4>
            <label class="text-lg">{{ 'ssab.claims.search.customer.label' | cxTranslate }}
              <span class="ssab-icon icon-inline"></span>
            </label>
            <ng-select #customer
                       [items]="claims?.allowedCustomers"
                       [searchable]="true"
                       [closeOnSelect]="true"
                       [clearable]="true"
                       (click)="toggleFilter(divCustomers)"
                       (change)="hideSearchOptions(divCustomers)"
                       [typeahead]="customerIdsInput$"
                       dropdownPosition="bottom"
                       bindLabel="name"
                       bindValue="uid"
                       id="customer_internal"
                       formControlName="customer"
                       (clear)="clearCustomers()"
                       placeholder="{{ 'ssab.order.history.status.selectAll' | cxTranslate }}"
            >
              <ng-template ng-option-tmp let-item="item">
                <span title="{{item.name}}">{{ item.name }}</span>
              </ng-template>
              <ng-template ng-footer-tmp>
                <p class="w-100 mb-0"
                   (click)="resetSelect(customer)">{{ 'ssab.order.history.status.selectAll' | cxTranslate }}</p>
              </ng-template>
            </ng-select>
          </div>

          <div #divCreationDateEnd class="form-group cx-claims-search-option col-lg-3 col-12"
               [ngClass]="{'d-lg-none': showHideFilter}">
            <label class="text-lg d-none d-lg-block">{{ 'ssab.claims.search.dateTo' | cxTranslate }}</label>
            <ssab-datepicker-input class="calendar-dark"
                                   [nameForm]="'creationDateEnd'"
                                   [idName]="'creationDateEnd'"
                                   [typeInput]="'text'"
                                   [parentForm]="searchForm"
                                   [isNotValidFunction]="isInvalid('creationDateEnd')"
                                   [customDayTemplate]="customDay"
                                   [labelPlaceholder]="'ssab.claims.search.dateTo'"
                                   [selectedDate]="convertDate(searchForm.get('creationDateEnd').value)"
                                   (click)="toggleFilter(divCreationDateEnd)"
                                   (onChangeEvent)="hideSearchOptions(divCreationDateEnd)"
                                   [navigation]="calendarNavigationTypes.Select"
            >
            </ssab-datepicker-input>
          </div>

          <!!--This is for making a break in the columns so buttons can be separated -->
          <div class="w-100 pb-3"></div>

          <div
            class="col-auto col-md-6 col-lg-6 text-white d-lg-block d-md-none text-bold justify-content-start d-flex align-self-center text-lg">
                        <span (click)="showHideFilterOptions()" class="cursor-pointer">
                          {{ 'ssab.order.history.filter.' + showHideFilter | cxTranslate }}
                          <i class="ssab-icon icon-system-filter icon-inline ml-3 icon-white"></i>
                        </span>
          </div>

          <div class="col-12 col-md-3 text-right form-group ml-md-auto d-md-block d-none">
            <button
              (click)="reset()"
              class="btn btn-link btn-default-height text-uppercase text-right p-0"
              type='button'>
              {{ 'ssab.search.reset' | cxTranslate }}
            </button>
          </div>

          <div class="col-12 col-md-3 form-group">
            <button
              class="btn btn-primary btn-default-height btn-block"
              type="submit">
              {{ 'ssab.search.resultTitle' | cxTranslate }}
            </button>
          </div>

          <div class="col-12 col-md-3 text-center form-group d-md-none d-block">
            <button
              (click)="reset()"
              class="btn btn-link btn-reset btn-default-height text-uppercase text-right p-0"
              type='button'>
              {{ 'ssab.search.reset' | cxTranslate }}
            </button>
          </div>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="user.externalUser">
      <div class="cx-claims-list-external">
        <div class="row align-items-center">
          <div class="col-12 col-lg-8 text-preamble">{{ 'ssab.claims.external.description' | cxTranslate }}</div>
          <div class="col-12 col-lg-4 text-right">
            <button (click)="gotoClaimRequest()" class="btn btn-primary mt-3 mt-lg-0">
              <span class="ssab-icon icon-navigation-plus-large icon-inline icon-white"></span>
              <span class="pl-2">{{ 'ssab.claims.new.claim.request' | cxTranslate }}</span>
            </button>
          </div>
        </div>
      </div>
    </ng-container>


    <ng-container *ngIf="claims$ | async as claims; else noDocuments">
      <div class="table-responsive">
        <table class="table cx-claims-table">
          <thead table-head
                 (sortChange)="sort($event)"
                 [activeSort]="activeSort"
                 [label]="'ssab.claims.list.'"
                 [tableHeadType]="getTableHeadType(user)">
          </thead>
          <tbody>
          <ng-container *ngFor="let claim of claims.claims">
            <ssab-cx-claim-list-item
              [claim]="claim"
              [allowedAssignees]="claims?.allowedAssignees"
              (doSearch)="initiateSearch()"
            ></ssab-cx-claim-list-item>
          </ng-container>
          </tbody>
        </table>
      </div>
      <div class="bg-white pt-4 pb-4 pr-5 text-right" *ngIf="user.internalUser">
        <button (click)="gotoClaimRequest()" class="btn btn-primary mt-3 mt-lg-0">
          <span class="ssab-icon icon-navigation-plus-large icon-inline icon-white"></span>
          <span class="pl-2">{{ 'ssab.claims.new.claim.request' | cxTranslate }}</span>
        </button>
      </div>
    </ng-container>
    <ssab-cx-pagination-bottom
      [selectedPageSize]="pageSize"
      [pagination]="claims?.pagination"
      [skipLocationChange]="true"
      (viewPageEvent)="pageChange($event)"
      (pageSizeEvent)="setPageSize($event)"
    ></ssab-cx-pagination-bottom>
  </ng-container>

  <ng-template #noDocuments>
    <div class="entries-data empty-data-div">

    </div>
  </ng-template>
</div>
