<div id="claimDetails" class="cx-claim-request-form" *ngIf="simulateClaim$ | async as ssabClaim">
  <h2 class="text-white cx-page-header-title" *ngIf="isRequestForm">
    {{ 'ssab.claim.request.form.page.title' | cxTranslate }}
  </h2>

  <form [formGroup]="form" class="" (ngSubmit)="onSubmit()" *ngIf="user$ | async as user">
    <div class="cx-claim-request-form-sections">
      <div class="cx-claim-request-form-section order-details">
        <div class="field-header">
          {{ 'ssab.claim.request.section.orderDetails' | cxTranslate }}
          <label *ngIf="isRequestForm">{{ 'ssab.claim.request.mandatoryFields' | cxTranslate }}</label>
        </div>
        <div #divCustomers class="field-content" *ngIf="user.internalUser">
          <label [ngClass]="{'required': isRequestForm}">{{ 'ssab.claim.request.customer'|cxTranslate }}</label>
          <div class="field-value">
            <ng-container *ngIf="isRequestForm">
              <ng-select
                [searchable]="true"
                [clearable]="false"
                [closeOnSelect]="true"
                dropdownPosition="bottom"
                bindLabel="name"
                bindValue="uid"
                [typeahead]="customerIdsInput$"
                (change)="onCustomerSelected($event)"
                formControlName="customer"
                [items]="customerIds$ | async"
                placeholder="{{ 'common.selectOption' | cxTranslate }}"
              ></ng-select>
            </ng-container>
            <ng-container *ngIf="!isRequestForm">
              <span>{{ ssabClaim.customer?.name }}</span>
            </ng-container>
          </div>
        </div>
        <div #divOrders class="field-content">
          <label [ngClass]="{'required': isRequestForm}">{{ 'ssab.claim.request.orderNumber'|cxTranslate }}</label>
          <div class="field-value">
            <ng-container *ngIf="isRequestForm">
              <ng-select
                [searchable]="true"
                [clearable]="false"
                [closeOnSelect]="true"
                dropdownPosition="bottom"
                [typeahead]="ordersInput$"
                (change)="onOrderSelected($event)"
                formControlName="orderNumber"
                [items]="orders$ | async"
                placeholder="{{ 'common.selectOption' | cxTranslate }}"
              ></ng-select>
            </ng-container>
            <ng-container *ngIf="!isRequestForm">
              <span>{{ ssabClaim.orderNumber }}</span>
            </ng-container>
          </div>
        </div>
        <div class="field-content">
          <label>{{ 'ssab.claim.request.poNumber'|cxTranslate }}</label>
          <div class="field-value">
            <span>{{ ssabClaim.orderNumber }}</span>
          </div>
        </div>
        <div class="field-content">
          <label [ngClass]="{'required': isRequestForm}">
            {{ 'ssab.claim.request.claimingFor' | cxTranslate }}
          </label>
          <div class="field-value">
            <ng-container *ngIf="isRequestForm">
              <div class="form-check mb-0 mr-3 d-inline-block">
                <input
                  class="form-check-input"
                  type="radio"
                  [checked]="!partialClaim"
                  (change)="setClaimType(false)"
                />
                <h6 class="form-check-label pb-3">
                  {{ 'ssab.claim.request.wholeOrder' | cxTranslate }}
                </h6>
              </div>
              <div class="form-check mb-0 d-inline-block">
                <input
                  class="form-check-input"
                  type="radio"
                  [checked]="partialClaim"
                  (change)="setClaimType(true)"
                />
                <h6 class="form-check-label pb-3">
                  {{ 'ssab.claim.request.partOfOrder' | cxTranslate }}
                </h6>
              </div>
            </ng-container>
            <ng-container *ngIf="!isRequestForm">
              <span *ngIf="ssabClaim.partialClaim">{{ 'ssab.claim.request.partOfOrder' | cxTranslate }}</span>
              <span *ngIf="!ssabClaim.partialClaim">{{ 'ssab.claim.request.wholeOrder' | cxTranslate }}</span>
            </ng-container>
          </div>
        </div>

        <div class="field-content">
          <label [ngClass]="{'required': isRequestForm}">{{ 'ssab.claim.request.orderLines' | cxTranslate }}</label>
          <div class="field-value">
            <ng-container *ngIf="isRequestForm">
              <ng-select
                [items]="ssabClaim.claimEntries"
                [searchable]="false"
                [multiple]="false"
                [closeOnSelect]="true"
                [clearable]="false"
                dropdownPosition="bottom"
                id="orderLine"
                bindLabel="orderLineName"
                bindValue="uid"
                formControlName="orderLines"
                placeholder="{{ 'common.selectMultiOption' | cxTranslate }}"
                (change)="addOrderLine($event)">
              </ng-select>
            </ng-container>

            <ng-container *ngIf="!isRequestForm">
              <!-- Non-request form display of order lines -->
              <div *ngFor="let entry of ssabClaim.claimEntries">
                <div>
                  <span>{{ entry.orderLineName }}</span>
                </div>
              </div>
            </ng-container>

            <!-- Display selected order lines below the input -->
            <div *ngIf="selectedOrderLines?.length > 0" class="selected-items-container">
              <ng-container *ngFor="let entry of selectedOrderLines">
                <div class="selected-item">
                  <span class="ssab-icon icon-navigation-close icon-inline cursor-pointer"
                        (click)="removeOrderLine(entry)"></span>
                  <span>{{ entry.orderLineName }}</span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="field-content">
          <label [ngClass]="{'required': isRequestForm}">{{ 'ssab.claim.request.materialIDs' | cxTranslate }}</label>
          <div class="field-value">
            <ng-container *ngIf="isRequestForm">
              <ng-select
                [items]="materials"
                [searchable]="false"
                [multiple]="false"
                [closeOnSelect]="true"
                [clearable]="false"
                dropdownPosition="bottom"
                id="materialIDs"
                bindLabel="materialId"
                bindValue="materialId"
                placeholder="{{ 'common.selectMultiOption' | cxTranslate }}"
                (change)="addMaterial($event)"
                formControlName="materialId">
              </ng-select>
            </ng-container>

            <ng-container *ngIf="!isRequestForm">
              <div *ngFor="let entry of ssabClaim.claimEntries">
                <div *ngFor="let material of entry.materials">
                  <span>{{ material.materialId }}  </span>
                  <span>{{ material.quantity }} {{ material.unit }}</span>
                </div>
              </div>
            </ng-container>

            <div *ngIf="selectedMaterials.length > 0" class="selected-items-container">
              <div *ngFor="let material of selectedMaterials; let i = index"
                   class="selected-item-row d-flex align-items-center">
                <div class="selected-item">
                  <span class="ssab-icon icon-navigation-close icon-inline cursor-pointer"
                        (click)="removeMaterial(material)"></span>
                  <span>{{ material.materialId }}</span>
                </div>
                <div class="text-right mr-3 flex-grow-1" *ngIf="this.partialClaim">
                  <label class="d-inline-block mr-3"
                         [ngClass]="{'required': isRequestForm}">{{ 'ssab.claim.request.claimedQty' | cxTranslate }}</label>
                  <input type="text" [formControl]="material.quantityControl"
                         placeholder="{{'ssab.claim.request.qty'|cxTranslate}}"
                         class="material-quantity-input form-control ssab-input">
                </div>
                <ng-select
                  *ngIf="this.partialClaim"
                  [formControl]="material.unitControl"
                  class="material-unit-select"
                  [searchable]="false"
                  [multiple]="false"
                  [closeOnSelect]="true"
                  [clearable]="false"
                  [items]="ssabClaim.allowedUnits"
                  (change)="updateMaterialUnit($event)"
                >
                  <ng-template ng-option-tmp let-item="item">
                    <span [innerHTML]="item"></span>
                  </ng-template>
                  <ng-template ng-label-tmp let-item="item">
                    <span [innerHTML]="item"></span>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
        </div>
        <div class="form-check" *ngIf="isRequestForm && !partialClaim">
          <input
            class="form-check-input position-relative"
            id="confirmFullClaim"
            type="checkbox"
            formControlName="confirmFullClaim">
          <label class="form-check-label pl-3 text-lg" [innerHtml]="'ssab.claim.request.confirm.claimmaterial' | cxTranslate"
                 for="confirmFullClaim"></label>
        </div>
      </div>
      <div class="cx-claim-request-form-section contact-details">
        <div class="field-header">
          {{ 'ssab.claim.request.section.contactDetails' | cxTranslate }}
          <label *ngIf="isRequestForm"></label>
        </div>
        <div class="field-content">
          <label>{{ 'ssab.claim.request.customerName'| cxTranslate }}</label>
          <div class="field-value">
            <ng-container *ngIf="isRequestForm && user.internalUser">
              <ssab-cx-input
                [typeInput]="'text'"
                [idName]="'customerName'"
                [nameForm]="'customerName'"
                [parentForm]="form">
              </ssab-cx-input>
            </ng-container>
            <ng-container *ngIf="(isRequestForm && !user.internalUser) || !isRequestForm">
              <span>{{ ssabClaim.customerName }}</span>
            </ng-container>
          </div>
        </div>
        <div class="field-content">
          <label>{{ 'ssab.claim.request.contactEmail'|cxTranslate }}</label>
          <div class="field-value">
            <ng-container *ngIf="isRequestForm">
              <ng-container formArrayName="contactEmails">
                <ng-container *ngFor="let contactEmailsArray of getContactEmails().controls; let i = index">
                  <div class="input-label d-flex mb-3">
                    <input [formControlName]="i" class="form-control ssab-input mb-0 flex-grow-1"/>
                    <button type="button" *ngIf="i === getContactEmails().length - 1"
                            class="btn btn-icon"
                            (click)="addContactEmail(null)">
                      <span class="ssab-icon icon-navigation-plus-small"></span>
                    </button>
                    <button type="button" *ngIf="i !== getContactEmails().length - 1"
                            class="btn btn-icon"
                            (click)="removeContactEmail(i)">
                      <span class="ssab-icon icon-navigation-minus-small"></span>
                    </button>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!isRequestForm">
              <span>{{ ssabClaim.claimContactEmails.join(", ") }}</span>
            </ng-container>
          </div>
        </div>
        <div class="field-content">
          <label>{{ 'ssab.claim.request.salesContact'|cxTranslate }}</label>
          <div class="field-value">
            <span>{{ ssabClaim.salesContactEmail }}</span>
          </div>
        </div>
        <div class="field-content">
          <label>{{ 'ssab.claim.request.technicalContact'|cxTranslate }}</label>
          <div class="field-value">
            <ng-container *ngIf="isRequestForm">
              <ssab-cx-input
                [typeInput]="'text'"
                [idName]="'technicalContact'"
                [nameForm]="'technicalContact'"
                [parentForm]="form">
              </ssab-cx-input>
            </ng-container>
            <ng-container *ngIf="!isRequestForm">
              <span>{{ ssabClaim.technicalContactEmail }}</span>
            </ng-container>
          </div>
        </div>
        <div class="field-content">
          <label>{{ 'ssab.claim.request.deliveryAddress'|cxTranslate }}</label>
          <div class="field-value">
            <span>{{ ssabClaim.claimDeliveryAddress }}</span>
          </div>
        </div>
        <div class="field-content">
          <label>{{ 'ssab.claim.request.deliveryChannel'|cxTranslate }}</label>
          <div class="field-value">
            <span>{{ ssabClaim.deliveryChannel }}</span>
          </div>
        </div>
      </div>
      <div class="cx-claim-request-form-section claim-details">
        <div class="field-header">
          {{ 'ssab.claim.request.section.claimDetails' | cxTranslate }}
          <label *ngIf="isRequestForm">{{ 'ssab.claim.request.mandatoryFields' | cxTranslate }}</label>
        </div>
        <div class="field-content">
          <label [ngClass]="{'required': isRequestForm}">{{ 'ssab.claim.request.claimSubject'|cxTranslate }}</label>
          <div class="field-value">
            <ng-container *ngIf="isRequestForm">
              <ssab-cx-input
                [typeInput]="'text'"
                [idName]="'claimSubject'"
                [nameForm]="'claimSubject'"
                [parentForm]="form">
              </ssab-cx-input>
            </ng-container>
            <ng-container *ngIf="!isRequestForm">
              <span>{{ ssabClaim.claimSubject }}</span>
            </ng-container>
          </div>
        </div>
        <div class="field-content">
          <label [ngClass]="{'required': isRequestForm}">{{ 'ssab.claim.request.defectType'|cxTranslate }}</label>
          <div class="field-value">
            <ng-container *ngIf="isRequestForm">
              <ng-select
                [searchable]="false"
                [clearable]="false"
                [closeOnSelect]="true"
                dropdownPosition="bottom"
                formControlName="defectType"
                [items]="ssabClaim.allowedDefectedTypes"
                placeholder="{{ 'common.selectOption' | cxTranslate }}"
              >
                <ng-template ng-option-tmp let-item="item">
                  <span>{{ "ssab.claims.defect." + item | cxTranslate }}</span>
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                  <span>{{ "ssab.claims.defect." + item | cxTranslate }}</span>
                </ng-template>
              </ng-select>
            </ng-container>
            <ng-container *ngIf="!isRequestForm">
              <span>{{ "ssab.claims.defect." + ssabClaim.defectType | cxTranslate }}</span>
            </ng-container>
          </div>
        </div>
        <div class="field-content">
          <label [ngClass]="{'required': isRequestForm}">{{ 'ssab.claim.request.defectedArea'|cxTranslate }}
            <span class="ssab-icon icon-inline icon-system-info ml-2" role="button"
                  [ngbPopover]="'ssab.claim.request.defectedAreaTooltip'|cxTranslate"
                  triggers="mouseenter:mouseleave"
                  container="body"
                  placement="top"
                  popoverClass="popover-fix-content popover-info ml-3">

            </span>
          </label>
          <div class="field-value">
            <ng-container *ngIf="isRequestForm">
              <ssab-cx-input
                [typeInput]="'text'"
                [idName]="'defectedArea'"
                [nameForm]="'defectedArea'"
                [parentForm]="form">
              </ssab-cx-input>
            </ng-container>
            <ng-container *ngIf="!isRequestForm">
              <span>{{ ssabClaim.defectedArea }}</span>
            </ng-container>
          </div>
        </div>
        <div class="field-content">
          <label [ngClass]="{'required': isRequestForm}">{{ 'ssab.claim.request.claimReason'|cxTranslate }}</label>
          <div class="field-value reason-text-area">
            <ng-container *ngIf="isRequestForm">
              <ssab-cx-input
                idName="claimReason"
                [nameForm]="'claimReason'"
                [parentForm]="form"
                [isTextArea]="true"
                [labelPlaceholder]="'common.write'"
              ></ssab-cx-input>
            </ng-container>
            <ng-container *ngIf="!isRequestForm">
              <span>{{ ssabClaim.claimReason }}</span>
            </ng-container>
          </div>
        </div>
        <div class="field-content">
          <div class="form-check" *ngIf="isRequestForm">
            <input
              class="form-check-input position-relative"
              id="urgent"
              type="checkbox"
              (change)="onUrgentChange($event)">
            <label class="form-check-label pl-3 text-lg" [innerHtml]="'ssab.claim.request.urgent' | cxTranslate"
                   for="urgent"></label>
          </div>
          <label [ngClass]="{'required': isRequestForm}"
                 *ngIf="isUrgent || ssabClaim.urgent">{{ 'ssab.claim.request.urgentReason' | cxTranslate }}</label>
          <div class="field-value">
            <ng-container *ngIf="isUrgent && isRequestForm">
              <ssab-cx-input
                [typeInput]="'text'"
                [idName]="'urgentReason'"
                [nameForm]="'urgentReason'"
                [parentForm]="form">
              </ssab-cx-input>
            </ng-container>
            <ng-container *ngIf="!isRequestForm && ssabClaim.urgent">
              <span>{{ ssabClaim.urgencyReason }}</span>
            </ng-container>
          </div>
        </div>
        <div class="field-content">
          <label>{{ 'ssab.claim.request.requestedCompensation'|cxTranslate }}</label>
          <div class="field-value">
            <ng-container *ngIf="isRequestForm">
              <ssab-cx-input
                [typeInput]="'text'"
                [idName]="'requestedCompensation'"
                [nameForm]="'requestedCompensation'"
                [parentForm]="form">
              </ssab-cx-input>
            </ng-container>
            <ng-container *ngIf="!isRequestForm">
              <span>{{ ssabClaim.requestedCompensation }}</span>
            </ng-container>
          </div>
        </div>
        <div class="field-content">
          <label>{{ 'ssab.claim.request.referenceId'|cxTranslate }}</label>
          <div class="field-value">
            <ng-container *ngIf="isRequestForm">
              <ssab-cx-input
                [typeInput]="'text'"
                [idName]="'referenceId'"
                [nameForm]="'referenceId'"
                [parentForm]="form">
              </ssab-cx-input>
            </ng-container>
            <ng-container *ngIf="!isRequestForm">
              <span>{{ ssabClaim.customerReferenceId }}</span>
            </ng-container>
          </div>
        </div>

        <ng-container *ngIf="isRequestForm">
          <button
            type="button"
            class="btn btn-primary"
            (click)="fileInput.click()">
            {{ 'ssab.claim.request.uploadFiles' | cxTranslate }}
          </button>

          <input
            #fileInput
            type="file"
            multiple
            style="display: none"
            (change)="onFilesSelected($event)"/>

          <div class="d-inline-block align-top ml-3"
               [innerHTML]="'ssab.claim.request.uploadInstructions' | cxTranslate">
          </div>

          <!-- Display selected files -->
          <div class="file-list mt-3">
            <div *ngFor="let file of uploadedFiles" class="file-item d-flex align-items-center">
              <!-- X button to delete -->
              <label
                class="pr-3 m-0"
                style="cursor: pointer;"
                (click)="removeFile(file)">
                <span class="ssab-icon icon-navigation-close"></span>
              </label>

              <!-- File name with link to download -->
              <a [href]="getFileUrl(file)" download="{{ file.name }}" class="file-link text-primary">
                {{ file.name }}
              </a>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!isRequestForm">
          <!-- list of files -->
        </ng-container>

      </div>
      <div class="cx-claim-request-form-section cx-claim-request-form-section-gray claim-notification"
           *ngIf="isRequestForm">
        <div [innerHTML]="'ssab.claim.request.claimInstructions'|cxTranslate"></div>
      </div>
    </div>
    <div class="row" *ngIf="!isRequestForm">
      <div class="col-12 text-right">
        <button
          type="button"
          class="btn btn-primary ml-3 mb-3"
          (click)="downloadPDF($event)">
          {{ 'ssab.claim.details.downloadPDF' | cxTranslate }}
        </button>
      </div>
    </div>
    <div class="row" *ngIf="isRequestForm">
      <div class="col-12 text-right">
        <div class="mr-3 mr-lg-0">
          <button
            type="button"
            class="btn btn-secondary ml-3 mb-3"
            (click)="onCancel()">
            {{ 'common.cancel' | cxTranslate }}
          </button>
          <button *ngIf="!user.internalUser && false"
                  type="button"
                  class="btn btn-tertiary ml-3 mb-3">
            {{ 'common.saveDraft' | cxTranslate }}
          </button>
          <button
            type="submit"
            class="btn btn-primary ml-3 mb-3">
            {{ 'common.submit' | cxTranslate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
