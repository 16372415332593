<div class="ssab-modal-md ssab-modal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title d-inline">
          {{ 'ssab.claims.assignee.modal.title'|cxTranslate }}
        </h3>
        <i class="float-right icon-md ssab-icon icon-navigation-close icon-inline" (click)="dismiss()"></i>
      </div>
      <div class="modal-body" [formGroup]="form">
        <p [innerHtml]="'ssab.claims.assignee.modal.description' | cxTranslate "></p>

        <div class="field-content">
          <label>{{ 'ssab.claims.list.assignee.label'|cxTranslate }}</label>
          <div class="field-value">
            <ng-container>
              <ng-select #assignee
                         [items]="allowedAssignees$ | async"
                         [clearable]="true"
                         [searchable]="true"
                         [closeOnSelect]="true"
                         [typeahead]="assigneesInput$"
                         (clear)="clearAssignees()"
                         dropdownPosition="bottom"
                         bindLabel="name"
                         bindValue="uid"
                         id="assignee"
                         formControlName="assignee"
                         placeholder="{{ 'common.selectOption' | cxTranslate }}">
                <ng-template ng-header-tmp>
                  <i class="ssab-icon icon-navigation-search icon-inline icon-gray-light"></i>
                  <input class="pl-2" placeholder="{{ 'ssab.search.resultTitle' | cxTranslate }}" style="line-height: 24px" type="text" (input)="assignee.filter($event.target.value)"/>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  {{ item.name + ' <' + item.customerId + '>' }}
                </ng-template>
              </ng-select>
            </ng-container>
          </div>
        </div>

        <div class="field-content">
          <label>{{ 'ssab.claims.assignee.modal.assignmentType'|cxTranslate }}</label>
          <div class="field-value">
            <ng-container *ngFor="let type of assigneeTypes | keyvalue">
              <div class="form-check mb-0 mr-3 d-inline-block" *ngIf="type.value">
                <input
                  class="form-check-input"
                  type="radio"
                  value="{{type.value}}"
                  formControlName="type"
                  (change)="changeType(type.value)"
                >
                <h6 class="form-check-label pb-3">
                  {{ 'ssab.claims.assignee.modal.assignment' + type.value |cxTranslate }}
                </h6>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="field-content" *ngIf="showTemporaryDates">
          <label>{{ 'ssab.claims.assignee.modal.assignmentDateRange'|cxTranslate }}</label>
          <div class="field-value">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="d-flex flex-row align-items-center">
                  <h4 class="mb-0">{{ 'common.from'|cxTranslate }}:</h4>
                  <ssab-datepicker-input class="calendar-dark"
                                         [nameForm]="'assignmentDateStart'"
                                         [idName]="'assignmentDateStart'"
                                         [typeInput]="'text'"
                                         [navigation]="'select'"
                                         [parentForm]="form"
                  >
                  </ssab-datepicker-input>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="d-flex flex-row align-items-center">
                  <h4 class="mb-0">{{ 'common.to'|cxTranslate }}:</h4>
                  <ssab-datepicker-input class="calendar-dark"
                                         [nameForm]="'assignmentDateEnd'"
                                         [idName]="'assignmentDateEnd'"
                                         [typeInput]="'text'"
                                         [navigation]="'select'"
                                         [parentForm]="form"
                  >
                  </ssab-datepicker-input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="field-content">
          <label>{{ 'ssab.claims.assignee.modal.assignmentMessage'|cxTranslate }}</label>
          <div class="field-value">
            <ssab-cx-input
              idName="message"
              [nameForm]="'message'"
              [parentForm]="form"
              [isTextArea]="true"
              [labelPlaceholder]="'common.write'"
            ></ssab-cx-input>
          </div>
        </div>

        <div class="buttons d-flex justify-content-center">
          <button class="btn btn-link text-uppercase" (click)="dismiss()">
            {{ 'common.cancel'|cxTranslate }}
          </button>
          <button class="btn btn-primary" (click)="assign()">
            {{ 'ssab.claims.assignee.modal.assign'|cxTranslate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
