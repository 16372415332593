<div class="row previous-orders-entry no-gutters d-flex">
  <div class="col-md-1 col-4">
    <a [routerLink]="{ cxRoute: 'product', params: entry.product } | cxUrl"
       class="align-self-start"
       cxModal="dismiss"
       tabindex="-1">
      <cx-media
        [container]="entry.product.images?.PRIMARY"
        format="thumbnail"
      ></cx-media>
    </a>
  </div>
  <div class="col-xxl-4 col-md-3 col-5 align-self-center pl-3" [ngClass]="entry.product?.stock?.canSplitBundle ?  'previous-orders-entry-text-split' : ''">
    <span class="text-opacity" *ngIf="!entry.product.nonSapProduct">{{ entry.product.erpMaterialCode }}</span><br/>
    {{ entry.product?.name }}
  </div>
  <div class="col-md-1 col-3 text-right text-md-center align-self-center" [ngClass]="entry.product?.stock?.canSplitBundle ?  'previous-orders-entry-text-split' : ''">
    {{ entry.originalQuantity }} {{ entry?.unit?.name }}
  </div>

  <div class="col-md-5 ml-auto col-12 pt-5 pt-md-0 align-self-center " [ngClass]="entry.product.buttonAction===undefined &&  getOutOfStockText()!==null ?  'row' : ''">
    <div class="previous-orders-entry-out-of-stock" *ngIf="entry.product.buttonAction===undefined &&  getOutOfStockText()!==null">
      {{ getOutOfStockText()|cxTranslate }}
    </div>
    <div *ngIf="entry.product.buttonAction!==undefined">
      <ssab-cx-add-to-cart
        *ngIf="currentStock$ | async as currentStock"
        [showQuantity]="getOutOfStockText()===null"
        [selectedUnit]="selectedUnit?.unit.code"
        [product]="entry.product"
        [initialQuantity]="entry.quantity"
        [currentStock]="currentStock"
        [minQuantity]="currentStock.minimumAvailableQuantity"
        [stockLevel]="currentStock.stockLevelDouble"
        (update)="onChange($event)"
        [isPLPView]="true"
        [isReorder]="true"
        [reorderTextKey]="getOutOfStockText()"
        [isSplitBundle]="entry.splitBundle"
      ></ssab-cx-add-to-cart>
    </div>
  </div>
</div>
