<div class="ssab-modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header p-0">
        <h4 class="modal-title d-inline">
          {{ 'ssab.scanner.home.title1' | cxTranslate }}
        </h4>
        <i class="float-right icon-md ssab-icon icon-navigation-close icon-inline" (click)="dismiss()"></i>
      </div>
      <div class="modal-body">
        <ul>
          <li>{{'ssab.scanner.howtoscan.list1'|cxTranslate}}</li>
          <li>{{'ssab.scanner.howtoscan.list2'|cxTranslate}}</li>
          <li>{{'ssab.scanner.howtoscan.list3'|cxTranslate}}</li>
          <li>{{'ssab.scanner.howtoscan.list4'|cxTranslate}}</li>
        </ul>
        <p class="mb-0" [innerHTML]="'ssab.scanner.howtoscan.description'|cxTranslate"></p>
        <div class="alert alert-info">
          <div class="container text-left d-flex align-items-center">
            <i class="ssab-icon icon-system-info mr-2 flex-shrink-0"></i>
            <span>
              <span [innerHTML]="'ssab.scanner.home.tubularInfoHowTo'|cxTranslate"></span>&nbsp;
              <span [innerHTML]="'ssab.scanner.home.tubularInfoAccess'|cxTranslate"></span>
            </span>
          </div>
        </div>

        <div ngbAccordion #accordion="ngbAccordion">
          <div ngbAccordionItem="item1">
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton>
                <span>{{'ssab.scanner.howtoscan.item1'|cxTranslate}}</span>
                <i class="ssab-icon icon-navigation-plus-small icon-sm"></i>
                <i class="ssab-icon icon-navigation-minus-small icon-sm"></i>
              </button>
            </h2>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>
                  <p [innerHTML]="'ssab.scanner.howtoscan.item1Text1'|cxTranslate"></p>
                  <img [src]="'ssab.scanner.howtoscan.item1Image1'|cxTranslate"/>
                </ng-template>
              </div>
            </div>
          </div>

          <div ngbAccordionItem="item2">
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton>
                <span>{{'ssab.scanner.howtoscan.item2'|cxTranslate}}</span>
                <i class="ssab-icon icon-navigation-plus-small icon-sm"></i>
                <i class="ssab-icon icon-navigation-minus-small icon-sm"></i>
              </button>
            </h2>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>
                  <h4 [innerHTML]="'ssab.scanner.howtoscan.item2Header1'|cxTranslate"></h4>
                  <p class="text-lg mb-2" [innerHTML]="'ssab.scanner.howtoscan.item2Header1a'|cxTranslate"></p>
                  <p [innerHTML]="'ssab.scanner.howtoscan.item2Text1a'|cxTranslate"></p>
                  <img [src]="'ssab.scanner.howtoscan.item2Image1a'|cxTranslate"/>
                  <p class="text-lg mb-2" [innerHTML]="'ssab.scanner.howtoscan.item2Header1b'|cxTranslate"></p>
                  <p [innerHTML]="'ssab.scanner.howtoscan.item2Text1b'|cxTranslate"></p>
                  <img [src]="'ssab.scanner.howtoscan.item2Image1b'|cxTranslate"/>
                  <p class="text-lg mb-2" [innerHTML]="'ssab.scanner.howtoscan.item2Header1c'|cxTranslate"></p>
                  <p [innerHTML]="'ssab.scanner.howtoscan.item2Text1c'|cxTranslate"></p>
                  <img [src]="'ssab.scanner.howtoscan.item2Image1c'|cxTranslate"/>
                </ng-template>
              </div>
            </div>
          </div>

          <div ngbAccordionItem="item3">
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton>
                <span>{{'ssab.scanner.howtoscan.item3'|cxTranslate}}</span>
                <i class="ssab-icon icon-navigation-plus-small icon-sm"></i>
                <i class="ssab-icon icon-navigation-minus-small icon-sm"></i>
              </button>
            </h2>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>
                  <p [innerHTML]="'ssab.scanner.howtoscan.item3Text1'|cxTranslate"></p>
                  <img [src]="'ssab.scanner.howtoscan.item3Image1'|cxTranslate"/>
                </ng-template>
              </div>
            </div>
          </div>

          <div ngbAccordionItem="item4">
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton>
                <span>{{'ssab.scanner.howtoscan.item4'|cxTranslate}}</span>
                <i class="ssab-icon icon-navigation-plus-small icon-sm"></i>
                <i class="ssab-icon icon-navigation-minus-small icon-sm"></i>
              </button>
            </h2>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>
                  <h4 [innerHTML]="'ssab.scanner.howtoscan.item4Header1'|cxTranslate"></h4>
                  <p [innerHTML]="'ssab.scanner.howtoscan.item4Text1'|cxTranslate"></p>
                  <img [src]="'ssab.scanner.howtoscan.item4Image1'|cxTranslate"/>
                  <h4 [innerHTML]="'ssab.scanner.howtoscan.item4Header2'|cxTranslate"></h4>
                  <p [innerHTML]="'ssab.scanner.howtoscan.item4Text2'|cxTranslate"></p>
                  <img [src]="'ssab.scanner.howtoscan.item4Image2'|cxTranslate"/>
                </ng-template>
              </div>
            </div>
          </div>

          <div ngbAccordionItem="item5">
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton>
                <span>{{'ssab.scanner.howtoscan.item5'|cxTranslate}}</span>
                <i class="ssab-icon icon-navigation-plus-small icon-sm"></i>
                <i class="ssab-icon icon-navigation-minus-small icon-sm"></i>
              </button>
            </h2>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>
                  <h4 [innerHTML]="'ssab.scanner.howtoscan.item5Header1'|cxTranslate"></h4>
                  <p [innerHTML]="'ssab.scanner.howtoscan.item5Text1'|cxTranslate"></p>
                  <p [innerHTML]="'ssab.scanner.howtoscan.item5TextWithIcon'|cxTranslate"></p>
                  <img [src]="'ssab.scanner.howtoscan.item5Image1'|cxTranslate"/>
                  <h4 [innerHTML]="'ssab.scanner.howtoscan.item5Header2'|cxTranslate"></h4>
                  <p class="text-lg mb-2" [innerHTML]="'ssab.scanner.howtoscan.item5Header2a'|cxTranslate"></p>
                  <p [innerHTML]="'ssab.scanner.howtoscan.item5Text2a'|cxTranslate"></p>
                  <img [src]="'ssab.scanner.howtoscan.item5Image2a'|cxTranslate"/>
                  <p class="text-lg mb-2" [innerHTML]="'ssab.scanner.howtoscan.item5Header2b'|cxTranslate"></p>
                  <p [innerHTML]="'ssab.scanner.howtoscan.item5Text2b'|cxTranslate"></p>
                  <img [src]="'ssab.scanner.howtoscan.item5Image2b'|cxTranslate"/>
                  <p class="text-lg mb-2" [innerHTML]="'ssab.scanner.howtoscan.item5Header2c'|cxTranslate"></p>
                  <p [innerHTML]="'ssab.scanner.howtoscan.item5Text2c'|cxTranslate"></p>
                  <img [src]="'ssab.scanner.howtoscan.item5Image2c'|cxTranslate"/>
                </ng-template>
              </div>
            </div>
          </div>

          <div ngbAccordionItem="item6">
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton>
                <span>{{'ssab.scanner.howtoscan.item6'|cxTranslate}}</span>
                <i class="ssab-icon icon-navigation-plus-small icon-sm"></i>
                <i class="ssab-icon icon-navigation-minus-small icon-sm"></i>
              </button>
            </h2>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>
                  <h4 [innerHTML]="'ssab.scanner.howtoscan.item6Header1'|cxTranslate"></h4>
                  <p [innerHTML]="'ssab.scanner.howtoscan.item6Text1'|cxTranslate"></p>
                  <img [src]="'ssab.scanner.howtoscan.item6Image1'|cxTranslate"/>
                  <h4 [innerHTML]="'ssab.scanner.howtoscan.item6Header2'|cxTranslate"></h4>
                  <p [innerHTML]="'ssab.scanner.howtoscan.item6Text2'|cxTranslate"></p>
                  <img [src]="'ssab.scanner.howtoscan.item6Image2'|cxTranslate"/>
                </ng-template>
              </div>
            </div>
          </div>

          <div ngbAccordionItem="item7">
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton>
                <span>{{'ssab.scanner.howtoscan.item7'|cxTranslate}}</span>
                <i class="ssab-icon icon-navigation-plus-small icon-sm"></i>
                <i class="ssab-icon icon-navigation-minus-small icon-sm"></i>
              </button>
            </h2>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>
                  <h4 [innerHTML]="'ssab.scanner.howtoscan.item7Header1'|cxTranslate"></h4>
                  <p class="text-lg mb-2" [innerHTML]="'ssab.scanner.howtoscan.item7Header1a'|cxTranslate"></p>
                  <p [innerHTML]="'ssab.scanner.howtoscan.item7Text1a'|cxTranslate"></p>
                  <img [src]="'ssab.scanner.howtoscan.item7Image1a'|cxTranslate"/>
                  <p class="text-lg mb-2" [innerHTML]="'ssab.scanner.howtoscan.item7Header1b'|cxTranslate"></p>
                  <p [innerHTML]="'ssab.scanner.howtoscan.item7Text1b'|cxTranslate"></p>
                  <img [src]="'ssab.scanner.howtoscan.item7Image1b'|cxTranslate"/>
                  <p class="text-lg mb-2" [innerHTML]="'ssab.scanner.howtoscan.item7Header1c'|cxTranslate"></p>
                  <p [innerHTML]="'ssab.scanner.howtoscan.item7Text1c'|cxTranslate"></p>
                  <img [src]="'ssab.scanner.howtoscan.item7Image1c'|cxTranslate"/>
                  <h4 [innerHTML]="'ssab.scanner.howtoscan.item7Header2'|cxTranslate"></h4>
                  <p class="text-lg mb-2" [innerHTML]="'ssab.scanner.howtoscan.item7Header2a'|cxTranslate"></p>
                  <p [innerHTML]="'ssab.scanner.howtoscan.item7Text2a'|cxTranslate"></p>
                  <img [src]="'ssab.scanner.howtoscan.item7Image2a'|cxTranslate"/>
                  <p class="text-lg mb-2" [innerHTML]="'ssab.scanner.howtoscan.item7Header2b'|cxTranslate"></p>
                  <p [innerHTML]="'ssab.scanner.howtoscan.item7Text2b'|cxTranslate"></p>
                  <img [src]="'ssab.scanner.howtoscan.item7Image2c'|cxTranslate"/>
                </ng-template>
              </div>
            </div>
          </div>
        </div>

        <button class="btn btn-primary btn-block" (click)="dismiss()">
          {{'ssab.scanner.howtoscan.close'|cxTranslate}}
        </button>
      </div>
    </div>
  </div>
</div>
